import React, { Component } from "react";
import Shell from "../Shell";
import Bars from "../../../../static/img/assets/bars.svg";
import FaqModal from "../../../components/home/faq-takeover.js";

export const FaqPage = props => {
  return (
    <>
      <Shell />
      <FaqScreen {...props} />
    </>
  );
};

class FaqScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.setState({
      loading: false
    });
  }
  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            color: "#000000",
            width: "100%",
            height: "100%",
            display: "grid",
            placeContent: "center center",
            background: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "100"
          }}
        >
          <img alt="please wait" src={Bars} />
        </div>
      );
    }
    return (
      <>
        <FaqModal backlink="/dashboard/hub/home" />
      </>
    );
  }
}
