import React, { useEffect, useState } from "react";
import {
  GameScreen,
  GameBoard,
  GameCard,
  CompleteButton,
  Message
} from "./StoryStyles";
import gameTitle from "../assets/gametitle.png";
import gameTextLives from "../assets/gametextlives.png";
import wrongX from "../assets/wrongx.png";
import okx from "../assets/okx.png";
import _ from "lodash";
import ReactCardFlip from "react-card-flip";
import axios from "axios";
import flipSound from "../../../static/sounds/flip.mp3";
import dingSound from "../../../static/sounds/ding.mp3";
import nomatchSound from "../../../static/sounds/wrong.mp3";
import WinMessagePic from "../../../static/img/assets/winmessage.png";

import Modal from "./Modal";

let cardFactory = size => {
  return _.shuffle(
    _.map(Array(size), (x, i) => {
      i = i + 1;
      if (i >= size / 2 + 1) {
        i = i - size / 2;
      }
      return {
        id: undefined,
        logo: undefined,
        deg: _.random(-3, 3),
        isflipped: 0,
        ismatched: 0
      };
    })
  );
};
const matchCheck = newSet => {
  let newSetFlipped = newSet.filter(x => x.isflipped);
  if (_.size(newSetFlipped) === 2) {
    let card1 = newSetFlipped[0];
    let card2 = newSetFlipped[1];
    if (
      !_.isUndefined(card1.id) &&
      !_.isUndefined(card2.id) &&
      _.isEqual(card1.id, card2.id)
    ) {
      return _.map(newSet, x => {
        if (x === card1 || x === card2) {
          x.ismatched = true;
        }
        return x;
      });
    }
  }
  return newSet;
};

const renderBoard = (box, key) => {
  return (
    <GameCard
      isflipped={box.isflipped}
      ismatched={box.ismatched}
      onClick={e => {
        if (box.ismatched) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }}
      key={key}
      deg={box.deg}
    >
      <ReactCardFlip
        infinite={false}
        isFlipped={box.ismatched || box.isflipped}
        flipDirection={false ? "vertical" : "horizontal"}
      >
        <div
          data-cardindex={_.toInteger(key)}
          className={box.isflipped ? "flipped front" : "unflipped front"}
        ></div>
        <div data-cardindex={_.toInteger(key)} className="back">
          <img
            className={box.isflipped ? "flipped" : ""}
            src={`https://givhub-production.s3.us-east-2.amazonaws.com/assets/ums/um${box.id}.jpg`}
          />
        </div>
      </ReactCardFlip>
    </GameCard>
  );
};
const cleanup = cards => {
  let newSet = _.clone(cards);
  let newSetFlipped = newSet.filter(x => x.isflipped);
  if (_.size(newSetFlipped) < 2) {
    return {
      newSet
    };
  }
  console.log(newSet);
  newSet = matchCheck(newSet).map((x, xi) => {
    x.isflipped = false;
    return x;
  });

  return {
    newSet
  };
};
const flip = (cards, index) => {
  let newSet = _.clone(cards);
  let wasmatch = false;
  let wasturn = false;
  let flippedcards = newSet.filter(x => x.isflipped);
  let numflipped = _.size(flippedcards);
  if (numflipped !== 2) {
    newSet[index].isflipped = true;
  }
  let newSetFlipped = newSet.filter(x => x.isflipped);
  if (_.size(newSetFlipped) === 2) {
    wasturn = true;
    let card1 = newSetFlipped[0];
    let card2 = newSetFlipped[1];
    if (
      !_.isUndefined(card1.id) &&
      !_.isUndefined(card2.id) &&
      _.isEqual(card1.id, card2.id)
    ) {
      wasmatch = true;
      newSet = _.map(newSet, x => {
        if (x === card1 || x === card2) {
          x.ismatched = true;
        }
        return x;
      });
    }
  }
  return {
    newSet,
    wasmatch,
    wasturn
  };
};
export const GameScreenPage = ({
  isOpen,
  apiHost,
  gameId,
  setisGame,
  setisFinalScreen,
  emailObject,
  avatar
}) => {
  let [gameState, setgameStatefn] = useState({
    lives: 0,
    matches: 0
  });
  let [gameModalOpen, setgameModalOpen] = useState(true);
  let [result, setLastResult] = useState(false);
  let [cards, setCardsfn] = useState(cardFactory(16));
  const setCards = val => {
    setCardsfn(val);
  };
  const setgameState = val => {
    setgameStatefn(val);
  };
  let [isLocked, setisLocked] = useState(false);
  let [finalModal, setFinalModal] = useState(false);
  useEffect(() => {
    console.log({ cards });
    let audio;
    if (result.wasturn) {
      audio = result.wasmatch ? new Audio(dingSound) : new Audio(nomatchSound);
      audio.volume = result.wasmatch ? 1 : 0.3;
      audio.playbackRate = result.wasmatch ? 1 : 2;
      audio.play();
      setgameState({
        lives: result.lives,
        matches: result.matches
      });
    }
  }, [cards]);
  useEffect(() => {
    if (result === false) return;
    if (result.newSet) {
      setCards(matchCheck(result.newSet));
    }
    if (result.wasturn) {
      _.delay(() => {
        setCards(cleanup(cards).newSet);
        _.delay(() => {
          setisLocked(false);
        }, 2000);
      }, 2000);
    } else {
      setisLocked(false);
    }
  }, [result]);

  useEffect(() => {
    console.log({ gameState });
    if (gameState.lives >= 3) {
      _.delay(() => {
        setFinalModal(true);
        emailObject.didplay = true;
        emailObject.didwin = result.matches == 8;
        axios
          .post(`${apiHost}/purchase/email`, emailObject)
          .catch(console.error);
      }, 1000);
    }
  }, [gameState]);

  return (
    <>
      {" "}
      <Modal
        isopen={finalModal}
        setIsOpen={setgameModalOpen}
        confetti={result.matches == 8}
        quote={
          result.matches !== 8 ? (
            "“Nice try. I was rooting for you!”"
          ) : (
            <>
              <div
                style={{
                  marginTop: "-5rem",
                  position: "relative",
                  height: "155px",
                  padding: "2rem",
                  marginBottom: "4rem"
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    left: "17.5px",
                    top: "1.75rem"
                  }}
                  src={WinMessagePic}
                />
              </div>
            </>
          )
        }
        message={
          result.matches !== 8 ? (
            <>
              You matched <strong>{_.toInteger(result.matches)}</strong> pairs
              out of the <strong>8</strong> sets of matching umbrellas before
              your third strike.
            </>
          ) : (
            <strong
              style={{
                fontFamily: "HelveticaNeue-Medium",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
                marginBottom: "1rem",
                display: "block"
              }}
            >
              Learn all about your 4 night, all-inclusive, Belize vacation for
              two on your email receipt. Also, a GivHub Travel Experience
              Manager will contact you within 2 business days.
            </strong>
          )
        }
        buttons={
          <CompleteButton
            onClick={() => {
              setisGame(false);
              setisFinalScreen(true);
            }}
            className="pink"
          >
            Next
          </CompleteButton>
        }
        avatar={avatar}
      />
      <Modal
        isopen={gameModalOpen}
        quote={'"Thank you so much for giving!"'}
        subquote={"Your donor reciept will be emailed"}
        skipHandle={() => {
          setisGame(false);
          setisFinalScreen(true);
        }}
        buttons={
          <>
            <CompleteButton
              onClick={() => {
                setgameModalOpen(false);
              }}
              className="pink"
              style={{
                marginBottom: "2rem"
              }}
            >
              Take Me To The Game
            </CompleteButton>
            <Message>Not interested in playing? Click below…</Message>
            <CompleteButton
              onClick={() => {
                setisGame(false);
                setisFinalScreen(true);
                axios
                  .post(`${apiHost}/purchase/email`, emailObject)
                  .catch(console.error);
              }}
              className="yellow"
            >
              NOT INTERESTED
            </CompleteButton>
          </>
        }
        message={
          <>
            Next, we invite you to play the classic card matching memory game
            for a chance to win a{" "}
            <strong>4 night, All-Inclusive Belize Vacation…</strong>
          </>
        }
        avatar={avatar}
      />
      <GameScreen isLocked={isLocked} isOpen={isOpen}>
        <img src={gameTitle} alt="title" />
        <div className="score">
          <img src={gameTextLives} alt="live" />
          <span>
            {_.times(3, x => {
              return (
                <div key={x}>
                  {gameState.lives <= x && (
                    <img className={"lives alive"} src={okx} alt="x" />
                  )}
                  <img
                    className={
                      gameState.lives > x ? "lives dead " : "lives deadno"
                    }
                    src={wrongX}
                    alt="x"
                  />
                </div>
              );
            })}
          </span>
          <span className="currentScore">{gameState.matches} / 8</span>
        </div>
        <GameBoard
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();
            let index = _.get(e, "target.dataset.cardindex");
            if (isLocked || _.isUndefined(index)) return;
            setisLocked(true);
            if (index) {
              let response = await axios.post(
                apiHost + "/games/" + gameId + "/" + index
              );
              let audio = new Audio(flipSound);
              audio.volume = 1;
              audio.playbackRate = 1.4;
              audio.play();
              let result = JSON.parse(response.data.Payload);
              cards[index] = result.newSet;
              result.newSet = cards;
              setLastResult(result);
            }
          }}
        >
          {cards.map(renderBoard)}
        </GameBoard>
      </GameScreen>
    </>
  );
};
