import React from "react";
import _ from "lodash";
import { navigate } from "gatsby";
import axios from "axios";
import { Container } from "./fragments/StoryStyles";
import { MetaHeader, LeftSide, MiddleSide, RightSide } from "./fragments/Comps";
let apiHost = "https://api.mygivhub.org";
// let apiHost = "http://localhost:8080";

export default class StoryDonatePage extends React.Component {
  constructor(props) {
    super(props);
    this.windowGlobal = this.windowGlobal || undefined;
    console.log(props.purchases);
    props.pageContext.data = JSON.stringify(props.pageContext.data).replace(
      /givhub\-production\.s3\.us\-east\-2\.amazonaws\.com/g,
      "staticassets.mygivhub.org"
    );
    props.pageContext.data = JSON.parse(props.pageContext.data);
    this.state = {
      pagedata: props.pageContext.data,
      maxHeight: undefined,
      goal: props.pageContext.data.goal,
      newProgress: _.reduce(
        props.pageContext.data.purchases,
        (p, n) => {
          if (n.issuccessful) return p + n.amount / 100;
          else return p;
        },
        0
      )
    };
  }
  updateFromDB = () => {
    if (this.props.pageContext.preview === true) {
      let mockPubed = this.props.pageContext.data;
      mockPubed.ispubed = true;
      return Promise.resolve(mockPubed);
    }
    if (this.windowGlobal) {
      let windowusername =
        _.get(this.state.pagedata, "user.donation_page_username") ||
        _.get(this.windowGlobal, "location.pathname", "")
          .split("/")
          .filter(x => x)
          .pop();
      let org =
        _.get(this.state.pagedata, "organization.campaign.url_slug") ||
        this.windowGlobal.location.pathname
          .split("/")
          .filter(x => x)
          .shift();
      if (!org) {
        console.error("/org", org);
        navigate("/");
        return;
      }
      return axios
        .get(apiHost + "/donation-pages/" + org + "/" + windowusername) //
        .then(result => {
          result = result.data.Payload;

          result = JSON.parse(result).data.donationpages.filter(x => {
            return x.user.donation_page_username === windowusername;
          });

          if (_.size(result) === 0) {
            // navigate("/");
            // console.log("result", result);
            return;
          }
          result[0] = JSON.stringify(result[0]).replace(
            /givhub\-production\.s3\.us\-east\-2\.amazonaws\.com/g,
            "staticassets.mygivhub.org"
          );
          result[0] = JSON.parse(result[0]);
          this.setState({
            pagedata: result[0]
          });
          console.log("Check it:", result[0]);
          return result[0];
        });
    }
  };
  componentDidMount() {
    this.windowGlobal = typeof window !== "undefined" ? window : false;
    this.updateFromDB();
    let maxHeight = Math.min(
      _.get(this.windowGlobal, "innerHeight", 0),
      _.get(this.windowGlobal, "document.body.clientHeight", 0)
    );
    this.setState({
      maxHeight
    });
  }
  render() {
    const page = this.props.pageContext.data;
    const props = this.props;
    // console.log("pagedata\n", this.state.pagedata);
    return (
      <>
        <MetaHeader page={page} />
        <Container>
          <LeftSide
            logo={_.get(this.state.pagedata, "organization.campaign.logo.url")}
            header={_.get(
              this.state.pagedata,
              "organization.campaign.caption",
              "GivHub Fundraiser"
            )}
            sponsor_text={_.get(
              this.state.pagedata,
              `organization.campaign.sponsor_text`,
              "Partners:"
            )}
            sponsors={_.get(
              this.state.pagedata,
              `organization.campaign.sponsors`
            )}
          />
          <MiddleSide
            isShowGoalBar={_.get(
              this.state.pagedata,
              "organization.campaign.is_show_goalbar",
              true
            )}
            pageid={_.get(this.state.pagedata, `id`)}
            apiHost={apiHost}
            updateFromDB={this.updateFromDB}
            userfinalslide={_.get(this.state.pagedata, "user_final_slide")}
            defaultfinalscreen={_.get(
              this.state.pagedata,
              `organization.campaign.default_final_slide.url`
            )}
            bgImage={_.get(
              this.state.pagedata,
              `organization.campaign.DefaultBackgroundImage.url`
            )}
            orgVideoPoster={_.get(
              this.state.pagedata,
              `organization.videoposter.url`
            )}
            orgVideo={_.get(this.state.pagedata, `organization.video.url`)}
            campVideoPoster={_.get(
              this.state.pagedata,
              `organization.campaign.videoposter.url`
            )}
            campVideo={_.get(
              this.state.pagedata,
              `organization.campaign.video.url`
            )}
            fullCamp={_.get(this.state.pagedata, `organization.campaign`)}
            isPreview={_.get(this.props, "pageContext.preview", false)}
            orgName={_.get(this.state.pagedata, "organization.name")}
            orgShortname={_.get(this.state.pagedata, "organization.shortname")}
            orgMission={_.get(this.state.pagedata, "organization.mission")}
            campMission={_.get(
              this.state.pagedata,
              "organization.campaign.mission"
            )}
            orglogo={_.get(
              this.state.pagedata,
              "organization.campaign.logo.url"
            )}
            userprofilepic={_.get(
              this.state.pagedata,
              "user.profile_picture.url"
            )}
            end_date={_.get(this.state.pagedata, `end_date`)}
            dbgoal={_.get(this.state.pagedata, `goal`)}
            purchases={_.get(this.state.pagedata, `purchases`)}
            maxHeight={this.state.maxHeight}
          />
          <RightSide
            userprofilepic={_.get(
              this.state.pagedata,
              "user.profile_picture.url"
            )}
            bgColorOverride={_.get(
              this.state.pagedata,
              "organization.campaign.color_b"
            )}
            userlogopic={_.get(this.state.pagedata, "user.namelogo.url")}
            username={
              _.get(this.state.pagedata, "user.firstname", "") +
              " " +
              _.get(this.state.pagedata, "user.lastname", "")
            }
            purchases={_.get(this.state.pagedata, `purchases`)}
          />
        </Container>
      </>
    );
  }
}

/*
[
                ({
                  header: {},
                  duration: 15 * 1000,
                  content: props => {
                    return (
                      <ScreenWrapper>
                        <video autoPlay>
                          <source
                            src="https://givhub-production.s3.us-east-2.amazonaws.com/37a46fe6939b498e9045c07df72660c3.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </ScreenWrapper>
                    );
                  }
                },
                {
                  url:
                    "https://givhub-production.s3.us-east-2.amazonaws.com/story/video.mp4",
                  type: "video"
                  // header: {
                  //   heading: "Wynn Williams",
                  //   subheading: "NATIONAL SUPERSTAR",
                  //   profileImage: userprofilepic
                  // }
                })
              ]*/
