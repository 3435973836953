import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Button, Popper, Fade, Backdrop } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  CircularProgress
} from "@material-ui/core";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthXs {
    max-width: 335px;
  }
  .MuiDialogTitle-root {
    h2 {
      font-family: HelveticaNeue-Medium;
      font-style: italic;
      font-weight: normal;
      font-size: 18px !important;
      line-height: 17px;
      letter-spacing: 0.14535px;
      color: #4a4a4a;
    }
  }
  .MuiDialogContentText-root {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.11305px;
    color: #4a4a4a;
  }
  .MuiDialogActions-root {
    justify-content: space-around;
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  height: 35px;
  width: ${props => props.width || "80px"};
  color: #fff;
  background-color: #e51670;
  font-family: "HelveticaNeue";
  grid-column: 2;
  grid-row: auto;
  justify-self: end;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 14px;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: bold;
  height: auto;
  min-width: 140px;
  min-height: 38px;
  align-self: flex-end;
`;

const CardImg = styled.img`
  max-width: 40px;
  margin-right: 0.6rem;
`;

const Section = styled.section`
  display: grid;
  grid-gap: 0px;
  width: 100%;
  filter: ${({ dimmed }) => (dimmed ? "brightness(.5)" : "none")};
`;

const SectionRow = styled.div`
  min-height: 55px;
  display: grid;
  grid-template-columns: 50px 1fr 135px;
  border-bottom: 0.5px solid #979797;
  align-items: center;
  padding: 1rem 0;
  @media (max-width: 500px) {
    height: auto;
    grid-template-columns: 35px 1fr;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 0;
  }
  &:nth-of-type(1) {
    border-top: 0.5px solid #979797;
  }
  div.dd {
    margin-left: 2rem;
    span {
      font-family: HelveticaNeue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #377ead;
    }
    div {
      font-family: HelveticaNeue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.171429px;
      color: #4a4a4a;
    }
  }
  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: self-end;
    @media (max-width: 500px) {
      grid-row: 2;
      grid-column: 1 / span 2;
    }
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.56px;
      color: #1264a3;
      padding: 0 1rem;
      &:nth-of-type(1) {
        border-right: 0.5px solid #979797;
      }
    }
  }
  div.logo {
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center center;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    background: #ffffff;
    border-radius: 8px;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
`;

const SectionText = styled.p`
  margin-top: ${props => props.margintop};
  grid-column: 1 / span 2;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #4a4a4a;
`;

export class AddHideOrgs extends React.Component {
  constructor(props) {
    super(props);
    let orgs = _.get(props, "props.state.orgs", [])
      .slice(1)
      .filter(org => !_.get(org, "fullresult.isdeleted"));
    console.log(orgs);
    this.state = {
      modal: false,
      orgs
    };
  }
  shouldComponentUpdate(props, state) {
    return true;
  }
  render() {
    return (
      <>
        <Section
          style={{
            gap: 0
          }}
          id="camp-settings-section"
        >
          <StyledDialog
            maxWidth="xs"
            onClose={() => {
              this.setState({
                modal: false
              });
            }}
            open={this.state.modal}
          >
            <DialogTitle id="simple-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                By permanently deleting this organization from your account, you
                will no longer be able to access stored data in Reports. You
                might consider hiding this organization instead to maintain your
                records.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <StyledButton
                style={{
                  background: "#CBCBCB"
                }}
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  this.setState({
                    modal: false
                  });
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                onClick={async e => {
                  e.preventDefault();
                  this.setState({
                    orgs: this.state.orgs.map(x => {
                      if (x.donid == this.state.chosenorg.donid) {
                        x.loading = true;
                      } else {
                        x.loading = false;
                      }
                      return x;
                    })
                  });
                  let updateObj = {
                    donationpageid: _.get(
                      this.state.chosenorg,
                      "fullresult.id",
                      false
                    ),
                    isdeleted: !_.get(
                      this.state.chosenorg,
                      "fullresult.isdeleted"
                    )
                  };
                  let result = await this.props.props.OrgUpdate(updateObj);
                  this.setState({
                    modal: false,
                    orgs: result.filter(
                      org => !_.get(org, "fullresult.isdeleted")
                    )
                  });
                }}
                color="primary"
              >
                Delete
              </StyledButton>
            </DialogActions>
          </StyledDialog>
          <SectionText>
            You can delete or hide the organizations in your dashboard at any
            time.
          </SectionText>

          {this.state.orgs
            .filter(org => !_.get(org, "fullresult.isdeleted"))
            .map((org, i) => (
              <SectionRow
                key={i}
                style={{
                  gridColumn: "1/3"
                }}
              >
                <div className="logo">
                  <CardImg src={org.campaign.logo.url} alt="visa" />
                </div>
                <div className="dd">
                  <span>{org.org.name}</span>
                  {/* <div>
                    Status, campaign settings, money raised, reports, etc.{" "}
                  </div> */}
                </div>
                <div className="buttons">
                  <a
                    onClick={async e => {
                      e.preventDefault();
                      this.setState({
                        modal: true,
                        chosenorg: org
                      });
                    }}
                  >
                    Delete
                  </a>
                  <a
                    onClick={async e => {
                      e.preventDefault();
                      this.setState({
                        orgs: this.state.orgs.map(x => {
                          if (x.donid == org.donid) {
                            x.loading = true;
                          } else {
                            x.loading = false;
                          }
                          return x;
                        })
                      });
                      let updateObj = {
                        donationpageid: _.get(org, "fullresult.id", false),
                        ishidden: !_.get(org, "fullresult.ishidden")
                      };
                      console.log(await this.props.props.setStateFromDB());
                      let result = await this.props.props.OrgUpdate(updateObj);
                      console.log({ result });
                      this.setState({
                        orgs: result
                      });
                    }}
                  >
                    {_.get(org, "loading", false) ? <CircularProgress /> : ""}
                    {!_.get(org, "loading", false) && (
                      <>
                        {_.get(org, "fullresult.ishidden") ? "Unhide" : "Hide"}
                      </>
                    )}
                  </a>
                </div>
              </SectionRow>
            ))}
          <StyledButton
            style={{
              marginTop: "1rem"
            }}
            onClick={() => {
              this.props.props.closeEx();
            }}
            variant="contained"
          >
            Close
          </StyledButton>
        </Section>
      </>
    );
  }
}
