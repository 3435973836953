import React, { Component } from "react";
import { Router, Redirect } from "@reach/router";
import PrivateRoute from "../../components/PrivateRoute";
import {
  // UserSettingsPage,
  HubHomePage,
  HubraisePage,
  HubAddOrgPage,
  NotFoundPage,
  BlankCards,
  OrgHomePage,
  FaqPage,
  EditDonationPage,
  DonationPreview,
  AddDonorsPage,
  UserSettingsScreen,
  VideoPage
} from "../../components/dashboard/pages/";

export default class Dashboard extends Component {
  render() {
    return (
      <Router basepath="/dashboard">
        <PrivateRoute path="/hub/home/" component={HubHomePage} />
        <PrivateRoute path="/hub/add-org/" component={HubAddOrgPage} />
        <PrivateRoute
          path="/hub/rewards/"
          cards={[{ title: "Rewards" }]}
          component={BlankCards}
        />

        <PrivateRoute
          path="/hub/store/"
          cards={[{ title: "Store" }]}
          component={BlankCards}
        />
        <PrivateRoute
          path="/hub/faqs/"
          cards={[{ title: "General" }]}
          component={FaqPage}
        />
        <PrivateRoute
          path="/hub/user/settings/"
          component={UserSettingsScreen}
        />
        <PrivateRoute path="/active-org/home/" component={OrgHomePage} />
        <PrivateRoute
          path="/donation/preview/:id"
          component={DonationPreview}
        />
        <PrivateRoute
          path="/active-org/donation-page/"
          component={EditDonationPage}
        />
        <PrivateRoute
          path="/active-org/training-and-resources"
          cards={[
            { title: "Training Videos" },
            { title: "Inspirational Stories" }
          ]}
          component={BlankCards}
        />

        <PrivateRoute
          path="/active-org/add-hubraisers/"
          component={HubraisePage}
        />

        <PrivateRoute
          path="/active-org/add-donors/"
          component={AddDonorsPage}
        />
        <PrivateRoute
          path="/active-org/promote-campaign/"
          cards={[{ title: "Promote" }]}
          component={BlankCards}
        />
        <PrivateRoute
          path="/active-org/marketing/"
          cards={[{ title: "Marketing" }]}
          component={BlankCards}
        />
        <PrivateRoute
          path="/active-org/video/"
          cards={[{ title: "Video" }]}
          component={VideoPage}
        />
        <PrivateRoute
          path="/active-org/reports/"
          cards={[{ title: "Reports" }]}
          component={BlankCards}
        />
        <PrivateRoute
          path="/active-org/hubraiser-rewards/"
          cards={[{ title: "Rewards" }]}
          component={BlankCards}
        />
        <PrivateRoute
          path="/active-org/store/"
          cards={[{ title: "Store" }]}
          component={BlankCards}
        />
        {/* <Redirect from="/" to="/dashboard/hub/home/" /> */}
        {/* <NotFoundPage default /> */}
      </Router>
    );
  }
}
