/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import _ from "lodash";
import React, { Component } from "react";
import profileVideoPoster from "../../../../static/img/assets/videoposters/profile.png";
import addOrgVideoPoster from "../../../../static/img/assets/videoposters/search.png";

import DatesCard from "../cards/DatesCard";
import LoadingCard from "../cards/LoadingCard";
import VideoCard from "../cards/VideoCard";
import IdeaCard from "../cards/IdeaCard";
import WelcomeCard from "../cards/WelcomeCard";
import { ShellPageMaker } from "../modules/PageFactory";
import {
  ContentCard,
  ContentCardColumn,
  ContentCardWrapper,
  ContentVideoCard
} from "../styles/shell-styles";
import Masonry from "react-masonry-css";

export const HubHomePage = props => (
  <ShellPageMaker {...props}>
    <HubHomePageScreen />
  </ShellPageMaker>
);

class HubHomePageScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading || !_.has(this.props, "activeMenu.activeOrg")) {
      return <LoadingCard />;
    }
    return (
      <Masonry
        breakpointCols={{
          default: 4,
          1440: 3,
          1200: 2,
          855: 1
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <ContentCard>
          <WelcomeCard />
        </ContentCard>

        <ContentVideoCard>
          <VideoCard
            title="Setting up your Profile"
            text="Learn how to set up your GivHub profile once, so you can leverage all of the great resources available in your dashboard."
            video={
              "https://givhub-production.s3.us-east-2.amazonaws.com/videos/settingupprofile-min.mp4"
            }
            videoposter={
              "https://givhub-production.s3.us-east-2.amazonaws.com/assets/setup.png"
            }
          />
        </ContentVideoCard>

        <ContentCard padding="0">
          <DatesCard />
        </ContentCard>

        <ContentVideoCard>
          <VideoCard
            title="Adding an Organization"
            text={`This video will show you the simple way to add a GivHub designed National or regional campaign to your
              personal account.`}
            video={
              "https://givhub-production.s3.us-east-2.amazonaws.com/videos/searchorg-min.mp4"
            }
            videoposter={
              "https://givhub-production.s3.us-east-2.amazonaws.com/assets/addingin.png"
            }
          />
        </ContentVideoCard>
        {/* <ContentVideoCard>
          <VideoCard
            title="Inviting others to Hubraise"
            text={`Expand your impact in the world by inviting others to GivHub to fundraise for the causes they care about. Learn how in this video.`}
            video={videoSettingup}
            videoposter={addOrgVideoPoster}
          />
        </ContentVideoCard> */}
        <IdeaCard title={"Make it a Competetion"}>
          <p>
            Invite friends & family to fundraise on GivHub for the same cause,
            or have everyone pick their own. Next, choose a 28 day, monthly or
            event based campaign, and agree on a start date…
            <br />
            <strong>ON YOUR MARK, GET SET, GO!</strong>
          </p>
        </IdeaCard>
      </Masonry>
    );
  }
}
