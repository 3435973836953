import _ from "lodash";
import React, { Component } from "react";
import BecomeImg from "../../../../static/img/assets/become-a-hubraiser.png";
import profileVideoPoster from "../../../../static/img/assets/videoposters/profile.png";
import addOrgVideoPoster from "../../../../static/img/assets/videoposters/search.png";
import videoSettingup from "../../../../static/video/instructional/settingupprofile-min.mp4";
import GetVerifiedCard from "../cards/GetVerifiedCard";
import LoadingCard from "../cards/LoadingCard";
import VideoCard from "../cards/VideoCard";
import WelcomeCard from "../cards/WelcomeCard";
import { ShellPageMaker } from "../modules/PageFactory";
import Masonry from "react-masonry-css";

import {
  ContentCard,
  ContentCardButton,
  ContentCardColumn,
  ContentCardWrapper,
  ContentVideoCard
} from "../styles/shell-styles";

export const OrgHomePage = props => {
  return (
    <ShellPageMaker {...props}>
      <OrgHomePageScreen />
    </ShellPageMaker>
  );
};

class OrgHomePageScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.setState({
      loading: false
    });
  }
  render() {
    if (this.state.loading || !_.has(this.props, "activeMenu.activeOrg")) {
      return <LoadingCard />;
    }
    return (
      <ContentCardWrapper>
        <Masonry
          breakpointCols={{
            default: 4,
            1440: 3,
            1200: 2,
            855: 1
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <ContentCard>
            <WelcomeCard
              logo={_.get(
                this.props,
                "activeMenu.activeOrg.org.logo.url",
                undefined
              )}
              copy={`You have successfully added ${this.props.activeMenu.activeOrg.name} to your personal GivHub dashboard. Now it’s time to add Hubraisers to your network, edit & publish your donation page, invite donors to give, and promote ${this.props.activeMenu.activeOrg.name} on social media.`}
            />
          </ContentCard>
          <ContentVideoCard>
            <VideoCard
              title={"Set Up Your Profile"}
              text={`Learn how to set up your GivHub profile once, so you can leverage all of the great resources available in your dashboard.`}
              video={
                "https://givhub-production.s3.us-east-2.amazonaws.com/videos/settingupprofile-min.mp4"
              }
              videoposter={
                "https://givhub-production.s3.us-east-2.amazonaws.com/assets/setup.png"
              }
            />
          </ContentVideoCard>

          <ContentCard padding={"0"}>
            <GetVerifiedCard
              titleImage={BecomeImg}
              CTAButton={ContentCardButton}
            />
          </ContentCard>

          <ContentVideoCard>
            <VideoCard
              title={`Becoming Verified`}
              text={`Coach, Advisor, Leader? Learn how to become Verified so you can build a team, manage menu functions, view reports and much more.`}
              video={
                "https://givhub-production.s3.us-east-2.amazonaws.com/videos/Verfied+Hubraiser_1+(2).mp4"
              }
              videoposter={
                "https://givhub-production.s3.us-east-2.amazonaws.com/videos/vlcsnap-2019-12-13-14h12m10s788.png"
              }
            />
          </ContentVideoCard>
          <ContentVideoCard>
            <VideoCard
              title={`Creating a Donation Page`}
              text={`This video will show you how to create, customize and publish your own personal donation page in minutes. `}
              video={
                "https://givhub-production.s3.us-east-2.amazonaws.com/ca17209f6ff14e27bacdef4757764d34.mp4"
              }
              videoposter={
                "https://givhub-production.s3.us-east-2.amazonaws.com/videos/vlcsnap-2019-12-12-16h53m26s706.png"
              }
            />
          </ContentVideoCard>
        </Masonry>
      </ContentCardWrapper>
    );
  }
}
