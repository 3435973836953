import React from "react";
import styled from "styled-components";
import Button from "react-video-recorder/lib/defaults/button";
import RecordButton from "react-video-recorder/lib/defaults/record-button";
import StopButton from "react-video-recorder/lib/defaults/stop-button";
import Timer from "react-video-recorder/lib/defaults/timer";
import Countdown from "react-video-recorder/lib/defaults/countdown";
import {
  CheckBox as CheckBoxIcon,
  Cached as CachedIcon
} from "@material-ui/icons";

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 80px;
`;

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  countdownTime,
  timeLimit,
  isReplayingVideo,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
  onConfirm
}) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <>
          <Button onClick={onTurnOnCamera}>
            <CachedIcon /> Re-Record
          </Button>
          <Button
            onClick={() => {
              onTurnOffCamera();
              onStopReplaying();
            }}
            data-qa="start-replaying"
          >
            <CheckBoxIcon /> Use This
          </Button>
        </>
      );
    }

    if (isRecording) {
      return <StopButton onClick={onStopRecording} data-qa="stop-recording" />;
    }

    if (isCameraOn && streamIsReady) {
      return (
        <RecordButton onClick={onStartRecording} data-qa="start-recording" />
      );
    }

    if (useVideoInput) {
      return (
        <Button onClick={onOpenVideoInput} data-qa="open-input">
          Upload a video
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button onClick={onOpenVideoInput} data-qa="open-input">
        Record a video
      </Button>
    ) : (
      <Button onClick={onTurnOnCamera} data-qa="turn-on-camera">
        Turn my camera ON
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

export default Actions;
