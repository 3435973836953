import React, { Component } from "react";
import Shell from "../Shell";
import Bars from "../../../../static/img/assets/bars.svg";
import Takeover404 from "../../../components/common/404Takeover";

export const NotFoundPage = props => {
  return (
    <>
      <Shell />
      <NotFoundScreen />
    </>
  );
};

class NotFoundScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.setState({
      loading: false
    });
  }
  render() {
    if (this.state.loading) {
      return (
        <>
          <img src={Bars} alt="loading" />
        </>
      );
    }
    return (
      <>
        <Takeover404 backlink="/dashboard" />
      </>
    );
  }
}
