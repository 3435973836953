import React, { Component } from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
class PrivateRoute extends Component {
  componentDidMount() {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }
  render() {
    const { component: Component, children, ...rest } = this.props;
    return <Component {...rest}>{children}</Component>;
  }
}

export default PrivateRoute;
