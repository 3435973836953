import React, { Component, useState } from "react";
import ContentModal from "../../common/ContentModal";
import Shell from "../Shell";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Button, Backdrop } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import Slider from "@material-ui/core/Slider";
import { Textfit } from "react-textfit";
import AvatarEditor from "react-avatar-editor";
import * as moment from "moment";

// import Bars from '../../../../static/img/assets/bars.svg';
import {
  ContentCard,
  ProfileCircle,
  NoProfileCircle
} from "../styles/shell-styles";
import {
  Lock as LockIcon,
  ExpandMore as ExpandMoreIcon,
  PhotoCamera as CameraIcon,
  PhotoSizeSelectSmall as SizeIcon
} from "@material-ui/icons";

import Visa from "../../../../static/styles/icons/visa.svg";
import MasterCard from "../../../../static/styles/icons/mastercard.svg";
import Bank from "../../../../static/styles/icons/bank.svg";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Text as TOSText } from "../../home/terms-of-service-takeover.js";
import { Text as PrivacyText } from "../../home/privacy-modal";
import Info from "../../../../static/styles/icons/info";

import { CampSettings } from "./settings-camps";
import { AddHideOrgs } from "./settings-addhideorgs";
const InfoIcon = styled(Info)`
  margin: 0 0 -0.7rem 0.4rem;
  width: 20px;
  height: 20px;
  fill: #4a4a4a;
`;

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const SliderHolder = styled.div`
  width: 100%;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;
  flex: 1 1 100%;
  align-items: flex-start;
  grid-column: 2;
  border: 1px solid #ddd;
  margin: 0;
  padding: 2rem;
  display: grid;
  grid-template-columns: 40px 1fr auto;
  grid-gap: 0.5rem;
  align-items: center;
  label {
    font-size: 1.8rem;
    align-items: center;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  span {
    font-size: 1.8rem;
    &.MuiSlider-root {
      color: #979797;
      width: 90%;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 2;
      justify-self: center;
      align-self: center;
    }
  }
`;

const AvatarButtonHolder = styled.div`
  width: 100%;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  grid-column: 1;
  border: 1px solid #dedede;
  padding: 1rem 2rem;
  display: block;
  align-items: center;
  display: grid;
  justify-content: center;
  label {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 1.8rem;
    svg {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
    }
  }
`;

const ExpansionPanelStyled = styled(ExpansionPanel)`
  &&.MuiTabs-flexContainer {
    padding-bottom: 1rem;
  }
  &.MuiExpansionPanel-root {
    border-radius: 0px;
    &.Mui-disabled {
      background-color: #fff;
    }
    &.Mui-expanded {
      border-radius: 0px;
    }
  }
  .MuiExpansionPanelDetails-root {
    padding: 0 2.4rem 2rem;
  }
  .MuiInputLabel-root {
    display: block;
    transform-origin: top left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.56px;
    color: #4a4a4a;
  }
`;

const StyledPlaneTextField = styled(TextField)`
  width: 100%;
  margin: 0;
  grid-column: 1 / span 2;

  input {
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.56px;
    color: #4a4a4a;
  }
`;

const StyledPlaneTextFieldNoBorder = styled(StyledPlaneTextField)`
  .MuiInput-root:before {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const StyledPlaneTextFieldWrapper = styled.div`
  position: relative;
  margin: 0 1.8rem;
  grid-column: 1 / span 2;
`;

const InputAdornment = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-family: "HelveticaNeue";
  font-size: 1.4rem;
  line-height: 30px;
  letter-spacing: 0.56px;
  color: #4a4a4a;
`;

const ShowFormBtn = styled.p`
  margin: ${props => props.margin};
  letter-spacing: 0.56px;
  color: #1264a3 !important;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  justify-self: end;
  grid-column: 2;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: end;
`;

const StyledButton = styled(Button)`
  height: 35px;
  width: ${props => props.width || "80px"};
  color: #fff;
  background-color: #e51670 !important;
  font-family: "HelveticaNeue";
  grid-column: 2;
  grid-row: auto;
  justify-self: end;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 14px;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: bold;
  height: auto;
  min-width: 150px;
  min-height: 38px;
  align-self: flex-end;
`;

const StyledButtonCancel = styled(Button)`
  height: 35px;
  width: 80px;
  color: #1264a3;
  background-color: #fff !important;
  font-family: "HelveticaNeue";
  justify-self: end;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 14px;
  text-transform: uppercase;
  border: 1.5px solid #1264a3;
  box-shadow: none;
`;

const StyledForm = styled.form`
  display: grid;
  margin-top: ${props => props.margintop};
  padding: 0 2rem;
  grid-template-columns: ${props => props.columns || "50% 50%"};
  grid-gap: 1rem;
  grid-column: ${props => props.gridColumn};
  width: 100%;
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: #1264a3 !important;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .MuiFormHelperText-root {
    overflow: hidden;
  }
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledFieldWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-column: ${props => props.gridColumn};
  grid-template-rows: 20px auto;
  grid-template-rows: min-content;
  @media (max-width: 425px) {
    grid-template-rows: auto auto;
  }
  > div.halfs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    @media (max-width: 425px) {
      grid-template-columns: 1fr;
    }
  }
`;

const Star = styled.span`
  margin-left: 0.6rem;
  color: #003c6a;
`;

const StyledTextFieldLabel = styled.span`
  margin: 0;
  font-family: "HelveticaNeue-Medium", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.56px;
  color: #4a4a4a;
`;

const StyledNativeSelect = styled(NativeSelect)`
  .MuiInputBase-input {
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.23);
    max-width: 100%;
    border-radius: 4px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    margin-bottom: 10px;
    min-height: 35px;
    padding: 0.7px 1.1rem;
    .MuiNativeSelect-icon {
      width: 20px;
    }
    .MuiFormHelperText-root {
      overflow: hidden;
    }
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    box-sizing: border-box;
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    background: #fafafa;
    align-self: center;
    border-radius: 4px;
    line-height: 17px;
    letter-spacing: 0.56px;
    .MuiFormHelperText-root {
      overflow: hidden;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.56px;
    color: #9b9b9b;
    word-break: break-all;
  }
`;

const Section = styled.section`
  display: grid;
  grid-gap: 10px;
  width: 100%;
`;

const SectionText = styled.p`
  margin-top: ${props => props.margintop};
  grid-column: 1 / span 2;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;

  color: #4a4a4a;
`;

const SectionTextWithBorder = styled(SectionText)`
  margin: ${props => props.margin || "0"};
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #999;
`;

const TTab = styled(Tab)`
  width: 33%;
  font-family: "HelveticaNeue";
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0.18px;
  text-transform: capitalize;

  .MuiTab-wrapper {
    color: #fff;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
    @media (max-width: 500px) {
      font-size: 1.2rem;
      white-space: normal;
      line-height: 1;
    }
  }

  &.MuiTab-root {
    @media (min-width: 960px) {
      min-width: auto;
    }
  }
`;
const AvWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding: 2rem;
  background: #00000011;
  grid-column: 1 / span 2;
  grid-row: 1;
  justify-self: center;
`;

const Tabss = styled(Tabs)`
  .PrivateTabIndicator-colorPrimary-642,
  .MuiTabs-indicator,
  .PrivateTabIndicator-colorPrimary-71 {
    height: 3px;
    background-color: #ebb424;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .PrivateTabIndicator-colorPrimary-26,
  .PrivateTabIndicator-colorPrimary-61 {
    background-color: #1264a3;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 2rem;
  h2 {
    font-family: "HelveticaNeue";
    font-weight: 600;
    font-stretch: normal;
    font-size: 1.4rem;
    line-height: 19px;
    letter-spacing: 0.16px;
    color: #4a4a4a;
    /* text-transform: capitalize; */
  }
  p {
    font-family: "HelveticaNeueLight", "HelveticaNeue-Light",
      "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue",
      "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial",
      sans-serif;
    font-stretch: normal;
    font-size: 1.4rem;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #4a4a4a;
    margin-top: 0%;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  margin: 0 1.8rem;
  padding-bottom: 1rem;
  grid-column: 1 / span 2;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  label {
    display: flex;
    width: 78%;
    align-items: center;
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #4a4a4a;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 0.6rem;
    -webkit-appearance: radio;
  }
`;

const StyledLabel = styled.label`
  font-family: HelveticaNeue-Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.56px;
  color: #4a4a4a;
  img {
    margin-right: 2rem;
  }
`;
const TextBtnsWrapper = styled.div`
  display: flex;
  width: 26%;
  justify-content: space-between;
  align-items: center;

  span {
    height: 16px;
    border-right: 1px solid #4a4a4a;
  }
`;

const TextBtn = styled.button`
  width: auto;
  height: auto;
  padding: 0;
  font-family: "HelveticaNeue";
  font-weight: normal;
  font-size: 1.4rem;
  border: none;
  border-radius: 0;
  line-height: 17px;
  letter-spacing: 0.56px;
  text-transform: initial;
  color: #1264a3;
`;

const CardImg = styled.img`
  max-width: 40px;
  margin-right: 0.6rem;
`;

const NationalYouthOrganizationTitles = [
  "Advisor",
  "Director",
  "Teacher",
  "Counselor",
  "Troop Leader",
  "Booster Club Officer"
];
const SchoolAthleticTitles = [
  "Athletic Director",
  "Head Coach",
  "Assistant Coach",
  "Booster Club Officer"
];
const CommunityCareUnitTitles = [
  "President",
  "Vice President",
  "Secretary",
  "Treasurer",
  "Board Member"
];
const NonProfitOrganizationTitles = [
  "Executive Director",
  "Director",
  "CEO",
  "President",
  "Vice President",
  "Director of Business Development",
  "Director of Fundraising"
];
const BoosterClubTitles = [
  "President",
  "Vice President",
  "Secretary",
  "Treasurer",
  "Administrative Liaison",
  "Executive Officer",
  "Recruitment Officer",
  "Communication Officer",
  "Fundraising Director"
];
const PerformingAndFineArtsTitles = [
  "Teacher",
  "Director",
  "Assistant Director",
  "Coach",
  "Advisor",
  "Booster Club Officer"
];
const ChurchReligiousInstitutionTitles = [
  "Elder",
  "Pastor",
  "Minister",
  "Associate Pastor",
  "Deacon",
  "Director",
  "Coordinator"
];
const ParaChurchOrganizationTitles = [
  "Executive Director",
  "Area Director",
  "CEO",
  "President",
  "Vice President",
  "Chief Operations Officer",
  "Chief Financial Officer",
  "Director of Business Development",
  "Director of Fundraising",
  "Student Staff"
];

const OrganizationTitles = [
  NationalYouthOrganizationTitles,
  SchoolAthleticTitles,
  CommunityCareUnitTitles,
  NonProfitOrganizationTitles,
  BoosterClubTitles,
  PerformingAndFineArtsTitles,
  ChurchReligiousInstitutionTitles,
  ParaChurchOrganizationTitles
];

const menuObject = [
  {
    "Profile Settings": {
      "Name and location": {
        isOpen: false,
        component: props => {
          // console.log("A", props);
          return (
            <StyledForm onSubmit={props.onUserFormSubmit}>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  First Name<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={e => {
                    props.updateUser("firstname", e.target.value);
                  }}
                  value={_.get(props, "state.user.user.firstname")}
                  placeholder="First Name"
                  required
                  autoComplete="fname"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Last Name<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={e => {
                    props.updateUser("lastname", e.target.value);
                  }}
                  type="text"
                  value={_.get(props, "state.user.user.lastname")}
                  placeholder="Last Name"
                  required
                  autoComplete="lname"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  City<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={e => {
                    props.updateUser("city", e.target.value);
                  }}
                  type="text"
                  value={_.get(props, "state.user.user.city")}
                  placeholder="City"
                  required
                  autoComplete="city"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  State<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledNativeSelect
                  onChange={e => {
                    props.updateUser("state", e.target.value);
                  }}
                  type="text"
                  disableUnderline
                  value={_.get(props, "state.user.user.state")}
                  placeholder="State"
                  maxLength={2}
                  required
                  autoComplete="state"
                  variant="outlined"
                  inputProps={{
                    name: "state"
                  }}
                >
                  <option value="" />
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </StyledNativeSelect>
              </StyledFieldWrapper>
              <StyledButton
                onClick={() => {
                  props.closeEx();
                }}
                variant="contained"
                type="submit"
              >
                Save
              </StyledButton>
            </StyledForm>
          );
        }
      },
      "My profile picture": {
        isOpen: false,
        component: props => {
          return (
            <StyledForm
              noValidate
              name="avatarform"
              onSubmit={props.onFormSubmit}
            >
              {true && (
                <>
                  <AvatarButtonHolder onClick={() => props.fileInput.click()}>
                    <label>
                      <CameraIcon />
                      Change Picture
                    </label>
                  </AvatarButtonHolder>

                  <AvWrapper onClick={() => props.fileInput.click()}>
                    <AvatarEditor
                      crossOrigin="anonymous"
                      disableBoundaryChecks={true}
                      ref={ref => (props.editor = ref)}
                      image={
                        _.get(
                          props,
                          "state.user.user.profile_picture.url",
                          "https://ui-avatars.com/api/?name=" +
                            props.state.user.user.firstname +
                            " " +
                            props.state.user.user.lastname
                        ) +
                        "?" +
                        _.random(1000000000000)
                      }
                      onPositionChange={() => {
                        props.setFileHander(
                          dataURLtoFile(
                            props.editor.getImage().toDataURL(),
                            "image.png"
                          )
                        );
                      }}
                      borderRadius={100}
                      width={150}
                      height={150}
                      border={1}
                      scale={props.scale}
                      style={{
                        border: "3px solid #fff",
                        borderRadius: "50%"
                      }}
                    />
                  </AvWrapper>
                  <SliderHolder>
                    <label>
                      <SizeIcon />
                    </label>
                    <Slider
                      min={1}
                      max={10}
                      step={0.05}
                      value={props.scale}
                      onChange={(e, v) => {
                        props.setScale(v);
                      }}
                      aria-labelledby="continuous-slider"
                    />
                    <span>Zoom</span>
                  </SliderHolder>

                  <input
                    ref={fileInput => (props.fileInput = fileInput)}
                    style={{
                      display: "none"
                    }}
                    required
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={props.onFileInputChange}
                  />
                </>
              )}
              {!_.has(props, "state.user.user.profile_picture") && (
                <>
                  {" "}
                  <input
                    ref={fileInput => (props.fileInput = fileInput)}
                    required
                    accept="image/jpg,image/jpeg"
                    type="file"
                    name="file"
                    onChange={props.onFileInputChange}
                  />
                </>
                // <NoProfileCircle onClick={() => props.fileInput.click()}>
                //   <Textfit mode="single">
                //     {`${_.get(props, 'state.user.user.firstname', '')} ${_.get(props, 'state.user.user.lastname', '')}`
                //       .split(' ')
                //       .reduce((p, n) => {
                //         return p + n.charAt(0).toUpperCase();
                //       }, '')}
                //   </Textfit>
                // </NoProfileCircle>
              )}
              <StyledButton
                onClick={() => {
                  props.closeEx();
                }}
                style={{
                  marginTop: "1rem"
                }}
                variant="contained"
                type="submit"
              >
                close
              </StyledButton>
            </StyledForm>
          );
        }
      },
      Username: {
        isOpen: false,

        component: props => {
          return (
            <div
              style={{
                display: "grid",
                alignItems: "baseline"
              }}
            >
              <p>
                Choose a unique Username that will serve as your personal
                identifier for all published content on the GivHub platform.
              </p>
              <StyledForm
                columns={"1fr"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end"
                }}
                onSubmit={props.onUserFormSubmit}
              >
                <StyledFieldWrapper>
                  <StyledTextFieldLabel>
                    Username<Star>*</Star>
                  </StyledTextFieldLabel>
                  <StyledTextField
                    fullWidth
                    style={{
                      width: "initial"
                    }}
                    margin="normal"
                    onChange={e => {
                      props.updateUser(
                        "donation_page_username",
                        e.target.value.replace(/\s/gim, "-")
                      );
                    }}
                    value={_.get(
                      props,
                      "state.user.user.donation_page_username"
                    )}
                    placeholder="Enter Username here"
                    required
                    helperText={
                      `https://www.mygivhub.org/organization/` +
                      _.get(props, "state.user.user.donation_page_username")
                    }
                    variant="outlined"
                  />
                </StyledFieldWrapper>
                <StyledButton
                  style={{
                    marginTop: "1rem"
                  }}
                  onClick={() => {
                    props.closeEx();
                  }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </StyledButton>
              </StyledForm>
            </div>
          );
        }
      },
      "Email addresses": {
        isOpen: false,

        component: props => {
          return (
            <Section>
              <SectionText>
                Update the Primary email addresses on your account
              </SectionText>
              <SectionTextWithBorder margin="0 1.8rem">
                Email:
              </SectionTextWithBorder>

              <StyledPlaneTextFieldWrapper>
                <StyledPlaneTextField
                  // disableUnderline
                  readOnly
                  value={_.get(props, "state.user.user.email")}
                />
                <InputAdornment>Primary</InputAdornment>
              </StyledPlaneTextFieldWrapper>

              {!props.showEmailForm && (
                <ShowFormBtn
                  margin="0 1.1rem 0 1.8rem"
                  onClick={() => props.showForm("showEmailForm")}
                >
                  Update email address
                </ShowFormBtn>
              )}

              {props.showEmailForm && (
                <StyledForm
                  margintop="1.8rem"
                  // onSubmit={props.onUserFormSubmit}
                  gridColumn="1/ span 2"
                  onSubmit={props.onUserFormSubmit}
                >
                  <StyledFieldWrapper gridColumn="1/span 2">
                    <StyledTextFieldLabel>
                      Update Email address
                    </StyledTextFieldLabel>
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      onChange={e => {
                        props.updateUser("email", e.target.value);
                      }}
                      value={_.get(props, "state.user.user.email")}
                      required
                      variant="outlined"
                    />
                  </StyledFieldWrapper>
                  <ButtonsWrapper>
                    <StyledButtonCancel
                      variant="contained"
                      onClick={() => props.showForm("showEmailForm")}
                    >
                      cancel
                    </StyledButtonCancel>
                    <StyledButton
                      variant="contained"
                      type="submit"
                      width="180px"
                    >
                      Update Email
                    </StyledButton>
                  </ButtonsWrapper>
                </StyledForm>
              )}
              <StyledButton
                style={{
                  marginTop: "1rem"
                }}
                onClick={() => {
                  props.closeEx();
                }}
                variant="contained"
              >
                Close
              </StyledButton>
            </Section>
          );
        }
      },
      "Phone numbers": {
        isOpen: false,

        component: props => {
          return (
            <Section>
              <SectionText>
                Add a phone number in case you have trouble signing in
              </SectionText>
              <SectionTextWithBorder margin="0 1.8rem">
                Phone numbers you’ve added:
              </SectionTextWithBorder>

              <StyledPlaneTextFieldWrapper>
                <StyledPlaneTextFieldNoBorder
                  id="standard-name"
                  readOnly
                  // disableUnderline
                  value={_.get(props, "state.user.user.phone")}
                />
                <InputAdornment>Primary</InputAdornment>
              </StyledPlaneTextFieldWrapper>
              {/* <RadioWrapper>
                <label>
                  <input type="radio" readOnly value="Use for password reset" defaultChecked />
                  Update Phone
                </label>
              </RadioWrapper> */}

              {!props.showPhoneForm && (
                <>
                  <SectionText>
                    Your phone number helps us keep your acount secure by adding
                    an additional layer of verification.
                  </SectionText>

                  <ShowFormBtn
                    margin="0 1.1rem 0 1.8rem"
                    onClick={() => {
                      props.showForm("showPhoneForm");
                    }}
                  >
                    Update phone number
                  </ShowFormBtn>
                  <StyledButton
                    onClick={() => {
                      props.closeEx();
                    }}
                    style={{
                      marginTop: "1rem"
                    }}
                    variant="contained"
                    type="submit"
                  >
                    close
                  </StyledButton>
                </>
              )}

              {props.showPhoneForm && (
                <StyledForm
                  margintop="1.8rem"
                  gridColumn="1/ span 2"
                  onSubmit={props.onUserFormSubmit}
                >
                  {/* <StyledFieldWrapper>
                    <StyledTextFieldLabel>Country</StyledTextFieldLabel>
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      // onChange={e => {
                      //   props.updateUser(
                      //     "donation_page_username",
                      //     e.target.value
                      //   );
                      // }}
                      // value={_.get(
                      //   props,
                      //   "state.user.user.donation_page_username"
                      // )}
                      placeholder="Enter email address  here"
                      required

                      variant="outlined"
                    />
                  </StyledFieldWrapper> */}
                  <StyledFieldWrapper>
                    <StyledTextFieldLabel>
                      New phone number
                    </StyledTextFieldLabel>
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      onChange={e => {
                        props.updateUser("phone", e.target.value);
                      }}
                      value={_.get(props, "state.user.user.phone")}
                      placeholder="Enter phone number here"
                      required
                      variant="outlined"
                    />
                  </StyledFieldWrapper>
                  <SectionText margintop="0">
                    {/* We’ll send you a code to this number, needed for the final step. */}
                  </SectionText>
                  <ButtonsWrapper>
                    <StyledButtonCancel
                      variant="contained"
                      onClick={() => props.showForm("showPhoneForm")}
                    >
                      cancel
                    </StyledButtonCancel>
                    <StyledButton
                      variant="contained"
                      type="submit"
                      width="180px"
                      onClick={() => {
                        props.showForm("showPhoneForm");
                      }}
                    >
                      Update Phone
                    </StyledButton>
                  </ButtonsWrapper>
                </StyledForm>
              )}
            </Section>
          );
        }
      },
      "Add/delete payment options": {
        disabled: true,
        isOpen: false,

        // icon: <LockIcon style={{ marginRight: '5px' }} />,
        component: props => {
          return (
            <Section>
              <SectionText>
                Add payment options to be used for charitable donations or
                purchases in the GivMore Store
              </SectionText>
              <SectionTextWithBorder margin="0 1.8rem">
                Your credit/debit cards:
              </SectionTextWithBorder>
              <RadioWrapper>
                <label>
                  <input
                    type="radio"
                    readOnly
                    value="Use for password reset"
                    checked
                  />
                  <CardImg src={Visa} alt="visa" />
                  Visa/Debit card ending in 1234
                </label>
                <TextBtnsWrapper>
                  <TextBtn>Delete Card</TextBtn>
                  <span></span>
                  <TextBtn>Edit</TextBtn>
                </TextBtnsWrapper>
              </RadioWrapper>
              <RadioWrapper>
                <label>
                  <input type="radio" readOnly value="Use for password reset" />
                  <CardImg src={MasterCard} alt="mastercard" />
                  MasterCard ending in 1234
                </label>
                <TextBtnsWrapper>
                  <TextBtn>Delete Card</TextBtn>
                  <span></span>
                  <TextBtn>Edit</TextBtn>
                </TextBtnsWrapper>
              </RadioWrapper>

              <ShowFormBtn margin="0 1.1rem 2.7rem 1.8rem">
                Add a new card
              </ShowFormBtn>
              <SectionTextWithBorder margin="0 1.8rem">
                Your bank accounts
              </SectionTextWithBorder>

              <RadioWrapper>
                <label>
                  <input type="radio" readOnly value="Use for password reset" />
                  <CardImg src={Bank} alt="bank" />
                  Frost Bank ----1234
                </label>
                <TextBtnsWrapper>
                  <TextBtn>Delete Account</TextBtn>
                  <span></span>
                  <TextBtn>Edit</TextBtn>
                </TextBtnsWrapper>
              </RadioWrapper>

              <ShowFormBtn margin="0 1.1rem 0 1.8rem">
                Add a bank account
              </ShowFormBtn>
            </Section>
          );
        }
      },
      "Change password": {
        isOpen: false,

        component: props => {
          return (
            <StyledForm onSubmit={props.onUserFormSubmit}>
              <SectionText>
                Choose a unique password to protect your account that will be
                hard for others to guess.
              </SectionText>

              <StyledFieldWrapper gridColumn="1/span 2">
                <StyledTextFieldLabel>
                  Type your current password
                </StyledTextFieldLabel>
                <StyledTextField
                  fullWidth
                  margin="normal"
                  // onChange={e => {
                  //   props.updateUser(
                  //     "donation_page_username",
                  //     e.target.value
                  //   );
                  // }}
                  // value={_.get(
                  //   props,
                  //   "state.user.user.donation_page_username"
                  // )}
                  placeholder="Enter current password here"
                  required
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Type your new password
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  // onChange={e => {
                  //   props.updateUser("firstname", e.target.value);
                  // }}
                  // value={_.get(props, "state.user.user.firstname")}
                  placeholder="Enter new password here"
                  required
                  // autoComplete="fname"

                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Retype your new password
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  // onChange={e => {
                  //   props.updateUser("lastname", e.target.value);
                  // }}
                  type="text"
                  // value={_.get(props, "state.user.user.lastname")}
                  placeholder="Enter new password here"
                  required
                  // autoComplete="lname"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledButton
                onClick={() => {
                  props.closeEx();
                }}
                variant="contained"
                type="submit"
              >
                Save
              </StyledButton>
            </StyledForm>
          );
        }
      }
    }
  },
  {
    "Partners & Services": {
      "Connect social media": {
        isOpen: false,

        component: () => {},
        disabled: true
      },
      "Permitted services or connected apps": {
        isOpen: false,

        disabled: true,
        component: () => {}
      },
      "Configure notifications": {
        isOpen: false,

        disabled: true,
        component: () => {}
      },
      "Sync contacts": {
        isOpen: false,

        disabled: true,
        component: () => {}
      }
    }
  },
  {
    "Manage Accounts": {
      "Become a Verified Hubraiser": {
        isOpen: false,

        disabled: false,
        component: props => {
          const OrgTitlesIndex =
            props.state.user.user.org_type > 0
              ? props.state.user.user.org_type - 1
              : 3;
          return (
            <StyledForm
              onSubmit={e => {
                props.onUserFormSubmit(e);
                props.closeEx();
              }}
            >
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  First Name<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={async e => {
                    props.updateUser("firstname", e.target.value);
                  }}
                  value={_.get(props, "state.user.user.firstname")}
                  placeholder="First Name"
                  required
                  autoComplete="fname"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Last Name<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={async e => {
                    props.updateUser("lastname", e.target.value);
                  }}
                  type="text"
                  value={_.get(props, "state.user.user.lastname")}
                  placeholder="Last Name"
                  required
                  autoComplete="lname"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  City<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={async e => {
                    props.updateUser("city", e.target.value);
                  }}
                  type="text"
                  value={_.get(props, "state.user.user.city")}
                  placeholder="City"
                  required
                  autoComplete="city"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  State<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledNativeSelect
                  onChange={async e => {
                    props.updateUser("state", e.target.value);
                  }}
                  type="text"
                  disableUnderline
                  value={_.get(props, "state.user.user.state")}
                  placeholder="State"
                  maxLength={2}
                  required
                  autoComplete="state"
                  variant="outlined"
                  inputProps={{
                    name: "state"
                  }}
                >
                  <option value="" />
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </StyledNativeSelect>
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Email address<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={async e => {
                    props.updateUser("email", e.target.value);
                  }}
                  value={_.get(props, "state.user.user.email")}
                  type="text"
                  disableUnderline
                  placeholder="Enter work email address here"
                  required
                  autoComplete="email-work"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Mobile phone number<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledTextField
                  margin="normal"
                  onChange={async e => {
                    props.updateUser("phone", e.target.value);
                  }}
                  type="text"
                  value={_.get(props, "state.user.user.phone")}
                  placeholder="Enter your mobile number here"
                  required
                  autoComplete="phone"
                  variant="outlined"
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledTextFieldLabel>
                  Organization or campaign name<Star>*</Star>
                </StyledTextFieldLabel>
                <StyledNativeSelect
                  onChange={e => {
                    e.persist();
                    props.updateUser("isForVH", true);
                    props.updateUser("org_for_verification", e.target.value);
                    const type = props.state.orgs.find(
                      org => org.org?.name === e.target.value
                    );
                    props.updateUser(
                      "org_type",
                      type.org.campaign.OrganizationType
                    );
                  }}
                  type="text"
                  disableUnderline
                  value={_.get(props, "state.user.user.org_for_verification")}
                  maxLength={2}
                  required
                  variant="outlined"
                >
                  <option></option>
                  {_.get(props, "state.orgs")
                    .slice(1)
                    .map((org, i) => (
                      <option
                        key={i}
                        value={org.org.name}
                      >{`${org.org.name}`}</option>
                    ))}
                </StyledNativeSelect>
              </StyledFieldWrapper>

              {props.state.user.user?.org_for_verification && (
                <StyledFieldWrapper>
                  <StyledTextFieldLabel>
                    Official Title or Position<Star>*</Star>
                  </StyledTextFieldLabel>
                  <StyledNativeSelect
                    type="text"
                    disableUnderline
                    placeholder="Enter title here"
                    variant="outlined"
                    onChange={e => {
                      e.persist();
                      props.updateUser("official-title", e.target.value);
                    }}
                    required
                    variant="outlined"
                  >
                    <option></option>
                    {OrganizationTitles[OrgTitlesIndex].map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </StyledNativeSelect>
                </StyledFieldWrapper>
              )}

              <StyledFieldWrapper
                style={{
                  gridColumn: "1 / span 2"
                }}
              >
                <StyledTextFieldLabel>
                  Provide a website or 3rd party phone number that connects you
                  to the organization<Star>*</Star>
                </StyledTextFieldLabel>
                <div className="halfs">
                  <StyledTextField
                    margin="normal"
                    type="text"
                    placeholder="Enter website here"
                    required
                    type="url"
                    variant="outlined"
                    onChange={async e => {
                      props.updateUser(
                        "website_for_verification",
                        e.target.value
                      );
                    }}
                  />
                  <StyledTextField
                    margin="normal"
                    type="text"
                    placeholder="Enter phone number here"
                    required
                    variant="outlined"
                    onChange={async e => {
                      props.updateUser(
                        "phone_for_verification",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </StyledFieldWrapper>
              <StyledButton variant="contained" type="submit">
                SUBMIT FORM
              </StyledButton>
            </StyledForm>
          );
        }
      },
      "Campaign Settings": {
        disabled: false,
        isOpen: false,

        component: props => {
          return <CampSettings props={props} />;
        }
      },
      "Verified account banking info": {
        disabled: true,
        isOpen: false,

        component: () => {}
      },
      "Delete or hide organizations": {
        isOpen: false,
        disabled: false,
        component: props => {
          return <AddHideOrgs props={props} />;
        }
      },
      "User Agreement": {
        disabled: false,
        isOpen: false,

        component: () => {
          return (
            <div style={{ maxHeight: "300px", overflow: "scroll" }}>
              <TOSText />
            </div>
          );
        }
      },
      "Privacy Policy": {
        disabled: false,
        isOpen: false,

        component: () => {
          return (
            <div style={{ maxHeight: "300px", overflow: "scroll" }}>
              <PrivacyText />
            </div>
          );
        }
      },
      "Merging GivHub accounts": {
        isOpen: false,

        disabled: true,
        component: () => {}
      },
      "Closing your GivHub account": {
        isOpen: false,

        disabled: true,
        component: () => {}
      }
    }
  }
];

const toggleMenu = (tab, menuTitle, menu) => {
  let result = menu;
  let TabResult = {};
  _.forEach(_.values(result[tab]).shift(), (x, i) => {
    if (i === menuTitle) x.isOpen = !x.isOpen;
    TabResult[i] = x;
  });
  return menu;
};

const showTab = (
  tab,
  props,
  updateUser,
  onUserFormSubmit,
  onFormSubmit,
  onFileInputChange,
  fileInput,
  editor,
  setFileHander,
  setScale,
  scale,
  showForm,
  showEmailForm,
  showPhoneForm,
  OrgUpdate,
  datesFocus,
  setLoading,
  menu,
  setMenu
) => {
  if (_.size(menu) === 0) {
    return;
  }
  let result = menu[tab];
  let list = Object.values(result)[0];

  return (
    <>
      {_.map(list, (section, title) => {
        return (
          <ExpansionPanelStyled
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              let objectMenu = menu;
              list = toggleMenu(tab, title, objectMenu);
              setMenu(objectMenu);
            }}
            expanded={section.isOpen}
            key={title}
            disabled={section.disabled}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
                component="h2"
              >
                {section.icon} {title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {section.component({
                updateUser,
                onUserFormSubmit,
                onFormSubmit,
                onFileInputChange,
                fileInput,
                editor,
                setFileHander,
                setScale,
                scale,
                showForm,
                showEmailForm,
                showPhoneForm,
                OrgUpdate,
                datesFocus,
                setLoading,
                menu,
                closeEx: e => {
                  if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  let objectMenu = menu;
                  list = toggleMenu(tab, title, objectMenu);
                  setMenu(objectMenu);
                },
                ...props
              })}
            </ExpansionPanelDetails>
          </ExpansionPanelStyled>
        );
      })}
    </>
  );
};
export const UserSettingsScreen = props => {
  return (
    <>
      <Shell {...props}>
        <UserSettings />
      </Shell>
    </>
  );
};

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.apiHost = props.apiHost;
    this.windowG = window;
    this.editor = undefined;
    this.state = {
      scale: 1,
      value: 0,
      file: undefined,
      loading: true,
      showEmailForm: false,
      showPhoneForm: false,
      datesFocus: "startDate",
      menu: []
    };
  }
  updateUser = (key, value) => {
    let user = this.props.state.user;
    user.user[key] = value;
    this.props.updateState({
      user
    });
  };
  setScale = v => {
    this.setState({
      scale: v
    });
  };
  setFileHander = file => {
    this.setState({
      file: file
    });
  };

  onUserFormSubmit = async e => {
    if (!!e) e.preventDefault();
    this.updateUser(
      "donation_page_username",
      _.kebabCase(_.get(this.props, "state.user.user.donation_page_username"))
    );
    let user = {
      ...this.props.state.user.user,
      donation_page_username: _.kebabCase(
        _.get(this.props, "state.user.user.donation_page_username")
      )
    };
    if (user.isForVH) {
      console.log({
        ...user,
        verificationdata: user
      });
    }
    await axios
      .post(
        this.apiHost + "/user/me",
        user.isForVH
          ? {
              ...user,
              verificationdata: user
            }
          : user,
        {
          headers: {
            "x-access-token": _.get(this.props, "state.user.jwt")
          }
        }
      )
      .catch(console.error);

    let userdata = await this.props.fetchUserInfo(
      _.get(this.props, "state.user.jwt")
    );
    userdata.isForVH = false;
    userdata.org_for_verification = undefined;
    this.props.updateState({
      user: userdata
    });
  };
  onFormSubmit = async e => {
    if (!!e) e.preventDefault(); // Stop form submit
    // this.props.updateState({
    //   loading: true
    // });
    this.fileUpload(this.state.file)
      .then(async data => {
        let userdata = await this.props.fetchUserInfo(
          _.get(this.props, "state.user.jwt")
        );
        this.props.updateState({
          user: userdata
        });
        this.setState({
          scale: 1
        });
      })
      .catch(console.error);
  };

  onFileInputChange = e => {
    let file = e.target.files[0] || this.state.file;
    this.setState({ file }, () => {
      this.onFormSubmit();
    });
  };

  OrgUpdate = obj => {
    return axios
      .post(this.apiHost + "/orgs/update", obj, {
        crossDomain: true,
        headers: {
          "x-access-token": _.get(this.props, "state.user.jwt")
        }
      })
      .then(async r => {
        let rsul = await this.props.setStateFromDB();
        return rsul;
      })
      .catch(console.error);
  };

  fileUpload = async file => {
    const url = this.apiHost + "/upload";
    const bodyFormData = new FormData();
    bodyFormData.append("refId", this.props.state.user.user.id);
    bodyFormData.append("field", "profile_picture");
    bodyFormData.append("ref", "user");
    bodyFormData.append("source", "users-permissions");
    bodyFormData.append("files", file);
    const config = {
      headers: {
        "x-access-token": _.get(this.props, "state.user.jwt"),
        "content-type": "multipart/form-data"
      }
    };
    return await axios.post(url, bodyFormData, config);
  };
  async componentDidMount() {
    this.setState({
      menu: menuObject
    });
    await this.init();
  }
  init = async () => {
    let userdata = await this.props.fetchUserInfo(
      _.get(this.props, "state.user.jwt")
    );
    this.props.updateState({
      user: userdata
    });
    if (window.location.hash === "#becomeaverifiedhubraiser") {
      this.setState({
        value: 2
      });
    }
    if (window.location.hash.indexOf("#manageaccount") > -1) {
      this.setState({
        value: 2
      });
      if (window.location.hash.indexOf("cp") > -1) {
        window.document
          .getElementById("camp-settings-section")
          .parentNode.parentNode.parentNode.parentNode.parentNode.previousElementSibling.click();
      }
    }
  };
  showForm = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  render() {
    let { value, loading } = this.state;
    if (loading) {
      <span>Loading</span>;
    }
    return (
      <ContentModal
        style={{
          background: "#1264A3"
        }}
        title={null}
        closeButtonFn={() => {
          window.history.go(-1);
        }}
        backlink={this.props.backlink || "/"}
      >
        <Tabss
          value={value}
          onChange={(o, v) => {
            this.setState({
              value: v
            });
          }}
          indicatorColor="primary"
        >
          <TTab label="Profile Settings" />
          <TTab label="Partners & Services" />
          <TTab label="Manage Accounts" />
        </Tabss>
        <Content>
          {showTab(
            value,
            this.props,
            this.updateUser,
            this.onUserFormSubmit,
            this.onFormSubmit,
            this.onFileInputChange,
            this.fileInput,
            this.editor,
            this.setFileHander,
            this.setScale,
            this.state.scale,
            this.showForm,
            this.state.showEmailForm,
            this.state.showPhoneForm,
            this.OrgUpdate,
            this.state.datesFocus,
            () => {
              this.setState({
                loading: !this.state.loading
              });
            },
            this.state.menu,
            menu => {
              this.setState({
                menu: menu
              });
            }
          )}
        </Content>
      </ContentModal>
    );
  }
}
