import React, { useState } from "react";
import IdeaIcon from "../../../templates/assets/ideaicon.png";
import {
  StyledModal,
  StyledPlayer,
  PlayButton,
  PlayButtonWrapper,
  BigPlayButton
} from "../styles/shell-styles";
import PlayButtonPNG from "../../../../static/styles/images/playbutton.png";
import styled from "styled-components";
import { ContentCard } from "../../dashboard/styles/shell-styles";

const Idea = styled(ContentCard)`
  background-color: #3f0f3f;
  color: #fff;
  border:0;
  max-width:365px ;
  @media (max-width:500px){
  max-width:auto ; 
  }
  h4 {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-content: center;
    justify-content: center;
    font-size: 1.8rem;
    align-items: center;
    grid-gap: 1rem;
    margin:0;
}
p{
  font-size:1.5rem;
  line-height:2.4rem;
}
  }
`;

export default ({ videoposter, video, children, title }) => {
  const [isOpen, setOpen] = useState(false);
  const [player, setPlayer] = useState(false);
  const [IsPlaying, setIsPlaying] = useState(false);
  return (
    <>
      <Idea>
        <h4>
          <img
            src={IdeaIcon}
            alt="video"
            style={{
              marginRight: "1rem"
            }}
          />
          {title}
        </h4>
        {children}
      </Idea>
    </>
  );
};
