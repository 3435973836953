import React, { useCallback, Component } from "react";
import Shell from "../Shell";
import Bars from "../../../../static/img/assets/bars.svg";
import { ContentCard } from "../styles/shell-styles";
import styled from "styled-components";
import _ from "lodash";
import axios from "axios";
import copy from "clipboard-copy";
import Button from "@material-ui/core/Button";
import Papa from "papaparse";
import { SnackbarProvider, withSnackbar } from "notistack";
import validator from "validator";
import { Link } from "gatsby";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
  WrapText
} from "@material-ui/icons";

import IdeaCard from "../cards/IdeaCard";

import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
// import { useDropzone } from "react-dropzone";
import LoadingCard from "../cards/LoadingCard";
import { navigate } from "gatsby";

import Masonry from "react-masonry-css";

const GridSpan = styled.span`
  gap: 0px 2rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-row-gap: 0;
  height: min-content;
  margin-left: 2rem;
  max-width: 740px;

  > div {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
    > div {
      max-width: 100%;
    }
  }
`;

const ContentCardStyled = styled(ContentCard)`
  min-width: 740px;
  padding-bottom: 2.5rem;
  height: 575px;
  @media (max-width: 850px) {
    min-width: auto;
    height: auto;
  }
  &&& label {
    display: grid;
    grid-row-gap: 4px;
    margin-bottom: 0;
    font-family: "HelveticaNeue";
    font-size: 1.2rem;
    color: #4a4a4a;
    letter-spacing: 0.17px;
    line-height: 18px;
  }

  &&& input {
    width: 100%;
    max-height: 30px;
  }

  &&& p {
    margin-bottom: 1.12rem;
    font-family: "HelveticaNeue";
    font-size: 1.4rem;
    a {
      color: #1264a3;
    }
  }
`;

const PageTitle = styled.h4`
  margin: 0;
  font-family: "HelveticaNeue-Bold";
  font-size: 2rem;
  color: #1264a3;
  line-height: 27px;
  margin-bottom: 1rem;
`;

const ColumnsMaker = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  @media (max-width: 846px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

const Form = styled.form`
  margin-top: 2rem !important;
  &::nth-of-type(0) {
    margin-top: 0 !important;
  }
`;

const Column = styled.div`
  display: grid;
  max-width: 320px;
  width: 320px;
  align-content: space-between;
  form:nth-of-type(1) {
    margin: 0 !important;
  }

  @media (max-width: 768px) {
    max-width: unset;
    width: auto;
  }
`;

const Fieldset = styled.fieldset`
  padding: ${props => props.padding || "1rem 1.4rem 2.5rem"};
  margin: 0;
  border: 2px solid #9b9b9b;
  border-radius: 6px;
  width: 100%;
`;

const Legend = styled.legend`
  padding: 0 0.8rem;
  font-family: "HelveticaNeueMedium", "HelveticaNeue-Medium";
  font-size: 1.4rem;
  color: #4a4a4a;
  letter-spacing: 0.3px;
`;

const FormLine = styled.div`
  display: grid;
  grid-template-columns: ${props => props.templateColumns || "1fr 1fr"};
  grid-gap: 1rem;
`;

const Input = styled.input`
  max-height: 30px;
  align-self: end;
  border-radius: 4px;
  border: 1px solid #4a4a4a;
  background-color: #f8f8f8;

  ::placeholder {
    font-family: "HelveticaNeueLight", "HelveticaNeue-Light",
      "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue",
      "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial",
      sans-serif;
    font-size: 1.4rem;
    color: #9b9b9b;
    letter-spacing: 0.3px;
  }
`;

const InputFilledPlaceholder = styled(Input)`
  ::placeholder {
    font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
      "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
      "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
    font-size: 1.1rem;
    color: #1264a3;
    letter-spacing: 0.24px;
  }
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 1.1rem;
  color: #1264a3;
  letter-spacing: 0.24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: center;
`;

const SubmitButton = styled.button`
  background: #e51670 !important;
  height: 30px;
  border-radius: 4px;
  background-color: #e51670;
  font-family: "HelveticaNeue-Bold";
  font-size: 13px !important;
  padding: 0 2rem;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
  max-width: ${({ mw }) => (mw ? mw : "max-content")};
  opacity: ${({ disabled }) => (disabled ? ".5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
`;

const TipText = styled.p`
  margin-top: 0;
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 1.2rem !important;
  font-style: italic !important;
  color: #4a4a4a;
  letter-spacing: 0.1px;

  position: relative;
  padding-left: 1rem;
  ::before {
    content: "*";
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const FormButton = styled.button`
  max-height: 29px;
  margin-top: 0 !important;
  background: #1264a3 !important;
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  margin-bottom: 1.4rem;
`;

const Field = styled.div`
  display: grid;
`;

const FieldName = styled.div`
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 1.2rem;
  color: #4a4a4a;
  letter-spacing: 0.17px;
  line-height: 18px;
  border-bottom: 1px solid #9b9b9b;
`;

const FieldValue = styled.div`
  padding-right: 1.6rem;
  font-family: "HelveticaNeueMedium", "HelveticaNeue-Medium";
  font-size: 1rem;
  color: #4a4a4a;
  letter-spacing: 0.14px;
  line-height: 18px;
`;

const InputUploadFile = styled.input`
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
`;

const LabelUploadFile = styled.label`
  display: inline-block;
  margin-bottom: 1.4rem !important;
  padding: 3.2rem 0;
  font-family: "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold",
    "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosBold", "Helvetica", "Tahoma",
    "Geneva", "Arial", sans-serif !important;
  font-size: 2.4rem !important;
  background: #f8f8f8;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  color: #d7d7d7 !important;
  letter-spacing: 0.51px !important;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 0rem !important;
  padding-right: 1rem;
`;
const SnackbarStyled = styled(Snackbar)`
  position: absolute;
  @media (max-width: 500px) {
    max-width: 300px;
  }
  span {
    font-size: 1.6rem;
    color: #fff;
  }
`;
export const AddDonorsPage = props => {
  const AddDonorsPageScreenWithSnack = withSnackbar(AddDonorsPageScreen);
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      maxSnack={3}
    >
      <Shell {...props}>
        <AddDonorsPageScreenWithSnack />
      </Shell>
    </SnackbarProvider>
  );
};
function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else if (cleaned.match(/^(\d{3})(\d{1,3})$/)) {
    match = cleaned.match(/^(\d{3})(\d{1,3})$/);
    return "(" + match[1] + ") " + match[2];
  } else if (cleaned.match(/(\d{3})$/)) {
    match = cleaned.match(/(\d{3})$/);
    return "(" + match[1] + ") ";
  } else {
    return cleaned;
  }
}
class AddDonorsPageScreen extends Component {
  constructor(props) {
    super(props);
    this.windowGlobal = typeof window !== "undefined" ? window : false;
    this.csvfileRef = React.createRef();
    this.state = {
      loading: true,
      showsnack: false,
      csvfile: false,
      user: {
        email: "",
        fname: "",
        lname: "",
        phone: ""
      }
    };
  }
  componentDidMount() {
    const contactPickersupported =
      "contacts" in navigator && "ContactsManager" in window;

    this.windowGlobal = typeof window !== "undefined" ? window : false;
    if (_.has(this.props, "activeMenu.activeOrg.campaign.name")) {
      this.setState({
        loading: false
      });
    } else {
      navigate("/dashboard");
    }
  }
  setFileHander = file => {
    Papa.parse(file, {
      worker: true,
      complete: results => {
        console.log(results);
        this.setState({
          csvfile: file
        });
      }
    });
  };

  userSubmit = async e => {
    if (e) e.preventDefault();
    this.setState({
      loading: false
    });
    await axios
      .post(
        this.props.apiHost + "/donor/invite",
        {
          ...this.state.user,
          image: this.props.activeMenu.activeOrg.campaign.logo.url,
          ref_name: this.props.state.user.user.firstname,
          campaign_name: this.props.activeMenu.activeOrg.campaign.name,
          url: `${_.get(
            this.windowGlobal,
            "location.origin",
            "https://mygivhub.org"
          )}/${_.kebabCase(
            _.get(this.props, "activeMenu.activeOrg.campaign.url_slug", "")
          ) +
            "/" +
            _.kebabCase(
              _.get(this.props.state, "user.user.donation_page_username")
            )}`
        },
        {
          headers: {
            "x-access-token": this.props.state.user.jwt
          }
        }
      )
      .then(() => {
        let dn = _.startCase(
          _.toLower(
            _.get(this.state, "user.fname", "") +
              " " +
              _.get(this.state, "user.lname", "")
          )
        );
        let sn =
          dn +
          ` (${_.get(this.state, "user.email", "")}) ` +
          " has been invited!";
        this.props.enqueueSnackbar(sn, {
          variant: "success"
        });
        this.setState({
          user: {
            email: "",
            phone: "",
            fname: "",
            lname: ""
          },
          // showsnack: true,
          loading: false
          //   snackmessage: sn
        });
      })
      .catch(console.error);
  };

  updateUser = e => {
    e.preventDefault();
    let key = _.get(e, "target.name", "");
    let user = _.get(this.state, "user");
    if (key === "phone") {
      e.target.value = formatPhoneNumber(e.target.value);
    }
    user[key] = _.get(e, "target.value", "");
    this.setState({
      user
    });
  };
  render() {
    if (this.state.loading) {
      return <LoadingCard />;
    }
    return (
      <>
        <ContentCardStyled>
          <SnackbarStyled
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={this.state.showsnack}
            onClose={() => {
              this.setState({
                showsnack: false
              });
            }}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.snackmessage}</span>}
            action={[
              <IconButton
                style={{
                  width: "50px"
                }}
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  this.setState({
                    showsnack: false
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
          {/* <code style={{ maxWidth: '400px', whiteSpace: 'pre-wrap' }}>{JSON.stringify(this.props)}</code> */}
          <PageTitle>
            Invite Donors to Give on Your Personal Donation Page
            {/* {_.get(this.props, "activeMenu.activeOrg.campaign.name", "")} */}
          </PageTitle>
          <ColumnsMaker>
            <Column>
              <Text>{`Invite donors to support your cause and let GivHub manage your communications.`}</Text>
              <Form onSubmit={this.userSubmit}>
                <Fieldset>
                  <Legend>Use Form to Invite Donors</Legend>
                  <FormLine>
                    <label htmlFor="name">
                      Enter name
                      <Input
                        value={_.get(this.state, "user.fname", undefined)}
                        onChange={this.updateUser}
                        type="text"
                        placeholder="*First Name"
                        required
                        autoComplete="fname"
                        id="fname"
                        name="fname"
                      />
                    </label>

                    <label htmlFor="lname">
                      <Input
                        value={_.get(this.state, "user.lname", undefined)}
                        onChange={this.updateUser}
                        type="text"
                        placeholder="*Last Name"
                        required
                        autoComplete="lname"
                        id="lname"
                        name="lname"
                      />
                    </label>
                  </FormLine>

                  <label>
                    Email address
                    <Input
                      value={_.get(this.state, "user.email", undefined)}
                      onChange={this.updateUser}
                      name="email"
                      type="text"
                      className="w100"
                      placeholder="*Enter email"
                      required
                    />
                  </label>
                  <label>
                    Cell number for SMS messaging
                    <Input
                      value={_.get(this.state, "user.phone", undefined)}
                      onChange={this.updateUser}
                      name="phone"
                      type="text"
                      placeholder="Enter cell number"
                      className="w100"
                      autoComplete="tel"
                    />
                  </label>
                  <SubmitButtonWrapper>
                    <SubmitButton type="submit">send invitation</SubmitButton>
                  </SubmitButtonWrapper>
                </Fieldset>
              </Form>

              <Form
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Fieldset>
                  <Legend>Manage Contacts to Invite Donors</Legend>
                  <TipText
                    style={{
                      lineHeight: "1.8rem",
                      marginTop: "1rem"
                    }}
                  >
                    Access your contacts to select the friends, family, and
                    coleagues that will receive invitations to give.
                  </TipText>
                  <SubmitButtonWrapper>
                    <SubmitButton
                      mw="230px"
                      onClick={async e => {
                        e.preventDefault();
                        const props = [
                          "name",
                          "email",
                          "tel",
                          "address",
                          "icon"
                        ];
                        const opts = { multiple: true };

                        try {
                          const contacts = await navigator.contacts.select(
                            props,
                            opts
                          );
                          console.log({ contacts });
                          _.map(contacts, c => {
                            this.props.enqueueSnackbar(c, {
                              variant: "error"
                            });
                          });
                        } catch (ex) {
                          alert(
                            "Sorry, Importing your contacts is not supported on this device"
                          );
                        }
                      }}
                    >
                      SELECT CONTACTS TO INVITE
                    </SubmitButton>
                  </SubmitButtonWrapper>
                </Fieldset>
              </Form>
            </Column>
            <Column>
              <Form>
                <Fieldset>
                  <Legend>Use CSV File to Invite Donors</Legend>
                  <TipText
                    style={{
                      lineHeight: "1.8rem",
                      marginTop: "1rem"
                    }}
                  >
                    Upload a CSV file with the following colums and GivHub will
                    take it from there. Cell not required. Other formats will
                    not be accepted.
                  </TipText>

                  <FieldsWrapper>
                    <Field>
                      <FieldName>First </FieldName>
                      <FieldValue>John</FieldValue>
                    </Field>
                    <Field>
                      <FieldName>Last</FieldName>
                      <FieldValue>Doe</FieldValue>
                    </Field>
                    <Field>
                      <FieldName>Email</FieldName>
                      <FieldValue>jdoe@hubraise.com</FieldValue>
                    </Field>
                    <Field>
                      <FieldName>Cell</FieldName>
                      <FieldValue>987-654-3210</FieldValue>
                    </Field>
                  </FieldsWrapper>

                  <InputUploadFile
                    ref={this.csvfileRef}
                    type="file"
                    accept="text/csv"
                    name="file"
                    id="file"
                    className="inputfile"
                    onChange={e => {
                      this.setFileHander(e.target.files[0]);
                    }}
                  />
                  {/* <LabelUploadFile htmlFor="file">
                    Drag a file here
                  </LabelUploadFile> */}
                  <FormLine
                    style={{
                      marginBottom: ".2rem"
                    }}
                    templateColumns="1fr 5fr"
                  >
                    <FormButton
                      style={{
                        fontSize: "1.3rem",
                        padding: "0 1.2rem"
                      }}
                      onClick={e => {
                        e.preventDefault();
                        this.csvfileRef.current.click();
                      }}
                    >
                      BROWSE
                    </FormButton>

                    <InputFilledPlaceholder
                      type="text"
                      placeholder="(blank)"
                      value={this.state.csvfile.name}
                      readOnly
                    />
                  </FormLine>
                  <SubmitButtonWrapper>
                    <SubmitButton
                      disabled={!this.state.csvfile}
                      onClick={e => {
                        e.preventDefault();
                        console.log(this.state.csvfile);
                        Papa.parse(this.state.csvfile, {
                          worker: true,
                          complete: () => {
                            this.state({
                              user: {
                                email: "",
                                phone: "",
                                fname: "",
                                lname: ""
                              }
                            });
                          },
                          step: row => {
                            let line = row.data.filter(x => x);
                            if (_.isEmpty(line) || line.length !== 4) {
                              let message = "Invalid Line:";
                              message += line.length
                                ? line.join(",")
                                : " (Empty)";
                              this.props.enqueueSnackbar(message, {
                                variant: "error"
                              });
                              return;
                            }
                            let isEmailValid = validator.isEmail("" + line[2]);
                            if (!isEmailValid) {
                              this.props.enqueueSnackbar(
                                line[2] + " is not a valid email",
                                {
                                  variant: "error"
                                }
                              );
                            }
                            let user = _.get(this.state, "user");
                            line[3] = formatPhoneNumber(line[3]);
                            user.fname = line[0];
                            user.lname = line[1];
                            user.email = line[2];
                            user.phone = line[3];
                            this.setState(
                              {
                                user
                              },
                              () => {
                                this.userSubmit();
                              }
                            );
                          }
                        });
                      }}
                    >
                      IMPORT & SEND INVITATIONS
                    </SubmitButton>
                  </SubmitButtonWrapper>
                </Fieldset>
              </Form>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log(e.target.elements[2]);
                  copy(e.target.elements[2].value);
                  this.props.enqueueSnackbar(
                    `${_.get(
                      e,
                      "target.elements[2].value",
                      ""
                    )} copied to your clipboard!`,
                    {
                      variant: "success"
                    }
                  );
                }}
              >
                <Fieldset
                  style={{
                    paddingBottom: "1.2rem"
                  }}
                >
                  <Legend>Use Link to Invite Donors</Legend>
                  <TipText>
                    Copy the link below to send via text or email
                  </TipText>
                  <FormLine templateColumns="1fr 5fr">
                    <FormButton
                      style={{
                        fontSize: "1.3rem",
                        padding: "0 1.2rem"
                      }}
                      type="submit"
                    >
                      copy
                    </FormButton>
                    <InputFilledPlaceholder
                      type="text"
                      name="linkurl"
                      value={`${_.get(
                        this.windowGlobal,
                        "location.origin",
                        "https://mygivhub.org"
                      )}/${_.kebabCase(
                        this.props.activeMenu.activeOrg.campaign.url_slug
                      ) +
                        "/" +
                        _.kebabCase(
                          _.get(
                            this.props.state,
                            "user.user.donation_page_username"
                          )
                        )}`}
                      placeholder={`${_.get(
                        this.windowGlobal,
                        "location.origin",
                        "https://mygivhub.org"
                      )}/${_.kebabCase(
                        this.props.activeMenu.activeOrg.campaign.url_slug
                      ) +
                        "/" +
                        _.kebabCase(
                          _.get(
                            this.props.state,
                            "user.user.donation_page_username"
                          )
                        )}`}
                      readOnly
                    />
                  </FormLine>
                </Fieldset>
              </Form>
              <Text
                style={{
                  marginTop: "2rem"
                }}
              >
                Click <Link to="/dashboard/active-org/reports/">HERE</Link> to
                visit the Reports section of your GivHub dashboard and see the
                status of your invited donors.
              </Text>
            </Column>
          </ColumnsMaker>
        </ContentCardStyled>
        <Masonry
          breakpointCols={{
            default: 4,
            2100: 2,
            1659: 1,
            1260: 2,
            854: 1
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <IdeaCard title={"Leverage Local Businesses"}>
            <p>
              Ask a local business to support you by emailing your fundraiser to
              employees or customers. Increase your odds of getting a “yes” by
              providing a pre-written email
            </p>
          </IdeaCard>
          <IdeaCard title={" Ask a Celebrity for a Shout-out"}>
            <p>
              Know a celebrity who supports your cause? Generate buzz by
              reaching out to them via social media for a shout out! Don’t
              forget to include your link!
            </p>
          </IdeaCard>
          <IdeaCard title={"Add Your Links to Your Bios"}>
            <p>
              Increase the exposure of your fundraiser by adding your link to
              your social media bios. Keep it there for the duration of your
              campaign.
            </p>
          </IdeaCard>
        </Masonry>
      </>
    );
  }
}
