import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { default as D } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { Message } from "../fragments/StoryStyles";
import Confetti from "react-confetti";
const AmountDiv = styled(DialogContentText)`
  font-size: 2rem;
`;
const FieldHeader = styled(DialogContentText)`
  margin: 0;
`;

const Dholder = styled.span``;

const Dialog = styled(D)`
  .MuiDialog-paper {
    overflow: visible;
    padding-top: 45px;
    min-width: 335px;
    width: 335px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.98);
    border-radius: 12px;
    background-color: #ffffff;
    padding-bottom: 2.5rem;
    align-self: flex-start;
    top: 25%;
  }
  .MuiDialogContent-root {
    padding: 0rem;
  }
  div.sizeHolder {
    display: flex;
    margin-top: 0rem;
    width: 300px;
    justify-content: space-between;
    &.buttons {
      grid-template-columns: 1fr 1fr;
      height: 32px;
      span.chooser {
        display: grid;
        grid-template-columns: 45px 45px 45px;
        border: 1px solid #979797;
        background: #f6f6f6;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 4px 4px 4px 4px;
        justify-content: center;

        &.total {
          grid-template-columns: 70px 70px;
        }
        div {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #1264a3;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.big {
            font-size: 2.5rem;
            font-family: HelveticNeue-Bold;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            background: #fff;
            border: 0;
            height: calc(100% - 0.25px);
            width: calc(100% - 0.25px);
            border: 0.5px solid #979797;
            border-top: 0;
            border-bottom: 0;
          }
        }
      }
    }
  }
  div.upsellmessage {
    font-family: HelveticaNeue;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0899786px;
    color: #4a4a4a;
    margin-top: 1.84rem;
  }
  div.spread {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto;
    width: 300px;
    margin-top: 3px;
    padding: 2px;
    &.gutterTop {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
    span {
      display: block;
    }
    span.item {
      font-family: HelveticaNeue-Bold;
      strong {
        font-size: 1.6rem;
      }
    }
    span:nth-of-type(2) {
      text-align: right;
      font-size: 10px;
      line-height: 18px;
      letter-spacing: 0.11305px;
      color: #1264a3;
    }
  }
  div.button {
    width: 50px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1264a3;
    background: #f6f6f6;
    font-style: normal;
    justify-content: space-evenly;
    border: 0.5px solid #979797;
    box-sizing: border-box;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    &.active {
      background: #1264a3;
      color: #f6f6f6;
    }
  }
  div.message {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.11305px;
    color: #4a4a4a;
    margin: 0;
    padding: 0 1.8rem;
    margin-bottom: 0rem;
  }
  .MuiIconButton-colorSecondary {
    transform: scale(1.3);
    transform-origin: 5px 0px;
    color: #1264a3;
    transform-origin: 10px center;
    color: #1264a3;
    padding: 0;
  }
  button {
    margin-top: 2rem;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    height: 35px;
    background-color: #e51670;
    width: 300px;
  }
  label {
    color: #4a4a4a;
    font-family: "HelveticaNeue-MediumItalic";
    font-size: 12px;
    font-style: normal;
    letter-spacing: 0.11px;
    line-height: 18px;
  }
`;

const InputHolder = styled.div`
  display: grid;
  > span {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 25% 25%;
  }
`;

const AvatarHolder = styled.div`
  position: absolute;
  width: 100%;
  display: grid;
  place-content: center;
  top: -45px;
  z-index: 100;
  div {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    width: 90px;
    height: 90px;
    border: 0.15rem solid #ffffff;
  }
`;
const Quote = styled.div`
  width: 100%;
  font-family: HelveticaNeue-MediumItalic;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.14535px;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  margin-top: 8px;
`;
const Sub = styled.div`
  width: 100%;
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-family: HelveticaNeue;
`;

export default function Modal({
  isopen,
  message,
  quote,
  avatar,
  buttons,
  subquote,
  confetti = false
}) {
  return (
    <>
      {confetti && (
        <Confetti
          width={"500px"}
          height={"500px"}
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: "100000",
            position: "absolute",
            top: "0"
          }}
        />
      )}
      <Dialog open={isopen} aria-labelledby="form-dialog-title">
        <AvatarHolder>{avatar}</AvatarHolder>
        <DialogContent className="">
          <Quote>{quote}</Quote>
          {subquote && <Sub>{subquote}</Sub>}
          <div className="message">{message}</div>
        </DialogContent>
        {buttons}
      </Dialog>
    </>
  );
}
