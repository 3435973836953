import Slider from "@material-ui/core/Slider";
import { navigate, Link } from "gatsby";
import { InputAdornment } from "@material-ui/core";
import { Popper, Fade, Backdrop } from "@material-ui/core";

import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";
import moment from "moment";
import VideoRecorder from "react-video-recorder";
import Shell from "../Shell";
import ysFixWebmDuration from "fix-webm-duration";
import {
  ContentCard,
  PaddedDiv,
  AmountInput,
  StyledModal,
  StyledPlayer,
  PlayButton,
  PlayButtonWrapper,
  BigPlayButton,
  ContentVideoCard
} from "../styles/shell-styles";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import like from "../../../../static/styles/icons/like.png";
import dislike from "../../../../static/styles/icons/dislike.svg";
import Info from "../../../../static/styles/icons/info.js";
import LoadingCard from "../cards/LoadingCard";
import IdeaCard from "../cards/IdeaCard";

import entryVideoExample from "../../../../static/video/example-intro.mp4";
import VideoCard from "../cards/VideoCard";
import customVideoAction from "../modules/VideoRender";

import Masonry from "react-masonry-css";

const ContentCardStyled = styled(ContentCard)`
  max-width: 740px;
  // max-width: calc(100vw - 4rem);
  padding: 0 2rem 1rem;
  border: 0.5px solid #9c9c9c;
  min-width: 740px;
  height: 595px;
  margin-bottom: 1rem !important;
  @media (max-width: 850px) {
    min-width: 320px;
    max-width: calc(100vw - 110px);
    max-width: 380px;
    width: calc(100vw - 20px);
    height: auto;
  }
  @media (max-width: 500px) {
    max-width: calc(100vw - 20px);
    width: calc(100vw - 20px);
  }
  filter: ${({ dimmed }) => (dimmed ? "brightness(.5)" : "none")};
`;

function blobToFile(theBlob, fileName, type = "") {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  console.log(theBlob);
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

const Wrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: 1245px) {
    grid-template-columns: 1fr;
  }
`;

const RowsDiv = styled.div`
  display: grid;
  max-width: 400px;
  padding: 0rem 0;
  height: 135px;
  div {
    min-width: 175px;
    display: grid;
    grid-template-columns: 20px auto 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    p {
      font-size: 1.6rem;
      font-family: HelveticaNeue;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin: 0.55rem 0.5rem;
      color: #4a4a4a;
      &:first-of-type {
        font-weight: normal;
      }
    }
  }
`;

const TShapeDiv = styled.div`
  margin-bottom: 1.5rem;
`;
const FiedsetbottomHolder = styled.span`
  display: grid;
  grid-template-columns: 25px auto 1fr;
  grid-gap: 1rem;
  align-items: baseline;
  max-height: 45px;
  svg {
    width: 100%;
    height: 100%;
    stroke-width: 3;
    color: #1264a3;
  }
  p {
    tab-size: 4;
    -webkit-font-smoothing: antialiased;
    box-sizing: inherit;
    font-family: HelveticaNeue;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4a4a4a;
    font-weight: normal;
  }
  img {
    align-self: center;
  }
  div,
  input.MuiInputBase-input.MuiInputBase-inputAdornedStart {
    margin: 0;
  }
  div.MuiFormControl-root {
    border: 1px solid #4a4a4a;
    padding: 0rem 1rem;
    background: #f8f8f8;
    border: 0.5px solid #4a4a4a;
    box-sizing: border-box;
    border-radius: 4px;
  }
  input.MuiInputBase-input.MuiInputBase-inputAdornedStart {
    margin-left: 0.5rem;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #4a4a4a;
    border: 0;
    margin: 1px;
  }
`;

const DonationFormWrapper = styled.form``;

const PageTitle = styled.h4`
  margin: 0 0 3rem;
  font-family: "HelveticaNeue";
  font-size: 2rem;
  color: #1264a3;
  line-height: 27px;
  width: 100%;
`;

const ColumnsMaker = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
  @media (max-width: 980px) {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    width: 100%;
  }
`;

const Column = styled.div`
  display: grid;
  max-width: 320px;
  grid-gap: 1rem;
  height: min-content;
  max-height: max-content;
  @media (max-width: 850px) {
    max-width: unset;
    min-width: 100%;
    &&:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
`;

const Section = styled.section`
  position: relative;
  display: grid;
  margin-bottom: ${props => props.marginBottom || "2rem"};
  padding: 0.5rem 1.5rem;
  border: 1.5px solid #9b9b9b;
  /* box-sizing: border-box; */
  height: fit-content;
  border-radius: 6px;
`;

const SectionTitle = styled.h5`
  position: absolute;
  top: -10px;
  left: 1.5rem;
  margin: 0;
  padding: 0 0.5rem;
  font-family: "HelveticaNeue";
  font-size: 1.4rem;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #4a4a4a;
  background-color: white;
  text-transform: capitalize;
  svg {
    margin-left: 1rem;
    fill: #1264a3;
  }
`;

const textStyle = css`
  margin-bottom: ${props => props.marginBottom};
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
`;

const SectionText = styled.p`
  ${textStyle}
`;
const SectionInput = styled.textarea`
  border: 0;
  margin-top: 1rem;
  color: #000;
  resize: none;
  ::placeholder {
    font-family: "HelveticaNeueLight", "HelveticaNeue-Light",
      "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue",
      "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial",
      sans-serif;
    font-size: 1.4rem;
    color: #9b9b9b;
    letter-spacing: 0.3px;
  }
  ${textStyle}
`;

const SectionTextItalic = styled.p`
  a {
    font-weight: bolder;
    font-family: "HelveticaNeue-Bold";
  }

  ${textStyle}
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  font-family: "HelveticaNeue-Light";
  color: #4a4a4a;
  a {
    font-style: normal;
    color: #1264a3;
  }
`;

const SectionTextBigFont = styled.p`
  ${textStyle}
  font-size: 14px !important;
`;

const LinkStyled = styled(Link)`
  font-style: normal;
  color: #1264a3;
`;

const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const SettingsColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 0.4rem;
  align-items: center;
`;

const SettingsColumnImg = styled.img`
  width: 20px;
  height: 20px;
`;

const SettingsColumnTitle = styled.span`
  ${textStyle}
  font-weight: bold;
  color: #4a4a4a;
  text-transform: capitalize;
  font-size: 12px;
`;

const CardNumber = styled.span`
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 1.2rem;
  text-align: right;
  line-height: 14px;
  letter-spacing: 0.257143px;
  color: #9b9b9b;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BtnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
  max-width: 320px;
  min-width: 100%;
  margin: 0;
  @media (max-width: 1000px) {
    padding-bottom: 1.5rem;
  }
  p {
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #4a4a4a;
    margin: 0;
  }
  ${SettingsColumnImg} {
    margin: 0 5px;
  }
`;

const generalButtonStyle = css`
  max-width: 100%;
  height: 35px !important;
  padding: 0 2rem;
  font-size: 14px;
  border: none;
`;

const BtnPreview = styled.button`
  ${generalButtonStyle};
  background: #1264a3 !important;
  white-space: nowrap;
`;

const BtnPublish = styled.button`
  ${generalButtonStyle};
  background: #e51670 !important;
  white-space: nowrap;
  opacity: ${props => (props.disabled ? ".5" : 1)};
  cursor: ${props => (props.disabled ? "no-drop" : "auto")};
`;

const RecordFrame = css`
  height: 330px;

  background-size: cover;
  border-radius: 5px;
  &:before {
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 92%;
    pointer-events: none;
  }
  &:after {
    background-color: rgba(0, 0, 0, 0.4);
    content: "Click to Record";
    padding-top: 6px;
    color: #b8a316;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: block;
    position: absolute;
    top: 72%;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

const RecordFrame1 = styled.div`
  ${RecordFrame}
  background-image: url("https://images.unsplash.com/photo-1485288734756-0b31a0a31d95?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80");
`;

const RecordFrame2 = styled.div`
  ${RecordFrame}
  background-image: url("https://images.unsplash.com/photo-1498462335304-e7263fe3925a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80");
`;

const RecordFramePSTyles = css`
  color: #fff;
  font-size: 12px;
  position: absolute;
`;

const DateField = styled.p`
  ${RecordFramePSTyles}
  top: 0px;
  left: 15px;
  margin: 8px 0;
`;

const RecordingLength = styled.p`
  ${RecordFramePSTyles}
  top: 0px;
  right: 15px;
  margin: 8px 0;
`;

const RecordingText = styled.p`
  ${RecordFramePSTyles}
  top: 80%;
  left: 30px;
  z-index: 2;
  font-size: 14px;
`;
const RecordingStatus = styled.p`
  ${RecordFramePSTyles}
  top: 80%;
  right: 30px;
  z-index: 2;
  font-size: 14px;
`;
const RedButton = styled.button`
  background: #d40e0d !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 25px !important;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%);
  border: 2px solid black !important;
  z-index: 2;
  &:before {
    background: none;
    border: 4px solid #fff;
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    pointer-events: none;
    border-radius: 50px;
  }
`;
const BlueButton = styled(BtnPublish)`
  background: #1264a3 !important;
`;

const SliderWrapper = styled.div`
  width: 100%;
  // padding: 0rem 3rem 0 2rem;
  display: flex;
  flex-direction: row;
  margin: 0rem 0rem;
  flex: 1 1 100%;
  align-items: flex-start;
  // display: none;
  span {
    flex-basis: 100%;
    &.MuiSlider-rail,
    &.MuiSlider-track {
      height: 1px !important;
      color: #4a4a4a;
    }
    &.MuiSlider-thumb {
      width: 14px;
      height: 14px;
      margin-top: -6px;
      background: #4a4a4a;
    }
    &.MuiSlider-mark {
      position: absolute;
      top: 10px;
      width: 5px;
      height: 5px;
      border: 1px solid #4a4a4a;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
`;

const AvatarSection = styled(Section)`
  padding-right: 1.7rem;
  padding-bottom: 2.6rem;
`;

const SliderAndAvatarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 980px) {
    justify-content: space-evenly;
  }
`;

const AvatarSliderHolder = styled.div`
  display: grid;
  grid-auto-rows: 4rem 10rem;
  row-gap: 1rem;
`;

const SliderStyled = styled(Slider)`
  .MuiSlider-rail,
  .MuiSlider-track {
    width: 1px !important;
    color: #4a4a4a;
  }
  .MuiSlider-thumb {
    left: 9px;
    width: 14px;
    height: 14px;
    background: #4a4a4a;
  }
  .MuiSlider-mark {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-51%);
    width: 5px;
    height: 5px;
    border: 1px solid #4a4a4a;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;
const VideoDialog = styled(Dialog)`
  div.wrapper {
    width: 375px;
    height: 812px;
    max-height: 100vh;
    background: #131313;
    overflow: hidden;
    @media (max-width: 414px) {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
`;
const AvatarHolder = styled.div`
  position: relative;
  margin-top: 1rem;
  text-align: right;
  cursor: pointer;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.42663);
  mix-blend-mode: normal;
  opacity: 0.72;

  & svg {
    width: 50px;
    height: 50px;
  }
`;

const SizeIconWrapper = styled.div`
  display: grid;
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 11px;
  text-align: center;
  color: #4a4a4a;

  & svg {
    width: 30px;
    height: 30px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    display: flex;
    margin-right: 0 !important;
    width: unset !important;
    align-items: center;
    font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
      "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
      "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0.171429px;
    color: #4a4a4a;
    &.disabled {
      opacity: 0.7;
    }
  }

  input {
    height: 20px !important;
    margin-top: 1rem;
    margin-left: 0.6rem;
  }
`;

const DateInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #4a4a4a;
`;

const AmountInputDate = styled(AmountInput)`
  margin-bottom: 0.4rem;
  font-weight: normal;
  .MuiInput-root:before {
    content: none;
  }
  &&& .MuiInputBase-input {
    width: 100% !important;
    text-transform: uppercase;
  }
`;

const InfoIcon = styled(Info)`
  margin: 0 0 -0.7rem 0.4rem;
  width: 20px;
  height: 20px;
  fill: #4a4a4a;
`;

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const OrgUpdate = _.debounce(async (apiHost, token, obj) => {
  console.log("UPDATE", obj);
  return await axios
    .post(apiHost + "/orgs/update", obj, {
      crossDomain: true,
      headers: {
        "x-access-token": token
      }
    })
    .catch(console.error);
}, 1000);

export const EditDonationPage = props => {
  return (
    <Shell>
      <EditDonationPageScreen {...props} />
    </Shell>
  );
};

class EditDonationPageScreen extends Component {
  constructor(props) {
    super(props);
    this.apiHost = props.apiHost;
    this.editor = undefined;
    console.log({ props }, "!!");
    this.fallbackFile = React.createRef(null);
    this.state = {
      scale: 1,
      file: undefined,
      loading: true,
      d1: false,
      dp: false,
      popperEl: null,
      isShowVideoDialog: false,
      videoduration: 0,
      videofile: {
        videotype: 1, // 1 for slide 1 and 2 for thank you slide
        videoBlob: null
      },
      videoPopup: {
        player: null,
        isOpen: false,
        video: null,
        isPlaying: false
      }
    };
  }

  onUserFormSubmit = _.debounce(async e => {
    if (!!e) e.preventDefault();
    await axios
      .post(this.apiHost + "/user/me", this.props.state.user.user, {
        headers: {
          "x-access-token": this.props.state.user.jwt
        }
      })
      .catch(console.error);
    let userdata = await this.props.fetchUserInfo(this.props.state.user.jwt);
    this.props.updateState({
      user: userdata
    });
  }, 1000);

  onFormSubmit = async e => {
    if (!!e) e.preventDefault(); // Stop form submit
    let user = this.props.state.user.user;
    if (
      _.isUndefined(user.profile_picture) ||
      _.isUndefined(user.donation_page_username) ||
      _.isUndefined(user.city) ||
      _.isUndefined(user.state)
    ) {
      alert("Missing Personal Profile Settings Information");
      return;
    }
    this.setState({
      loading: true,
      dp: true
    });
    if (this.state.file) {
      await this.fileUpload(this.state.file).catch(console.error);
    }
    const config = {
      crossDomain: true,
      headers: {
        "x-access-token": this.props.state.user.jwt
      }
    };
    let activeOrg = this.props.activeMenu.activeOrg;
    OrgUpdate(this.apiHost, this.props.state.user.jwt, {
      published: activeOrg.campaign.id,
      donationpageid: activeOrg.donid
    });
    await axios
      .post(
        this.apiHost + "/publish",
        {
          donationpageid: activeOrg.donid,
          pub: !Boolean(activeOrg.ispubed)
        },
        config
      )
      .catch(console.error);
    await this.props.setStateFromDB().catch(console.error);
    this.setState({
      loading: false,
      scale: 1
    });
  };

  onFileInputChange = e => {
    let file = _.get(e.target, "files[0]", this.state.file);
    this.setState({ file }, () => {
      this.fileUpload(this.state.file)
        .then(async data => {
          await this.props.setStateFromDB();
          this.setState({
            loading: false,
            scale: 1
          });
        })
        .catch(console.error);
      //this.onFormSubmit();
    });
  };

  fileUpload = async (
    file,
    { source = "", ref = "donationpage", field = "profile_section_images" }
  ) => {
    this.setState({
      loading: true
    });
    const url = this.apiHost + "/upload";
    const bodyFormData = new FormData();
    bodyFormData.append("refId", this.props.activeMenu.activeOrg.donid);
    //console.log("FFF", file);
    bodyFormData.append("field", field);
    bodyFormData.append("ref", ref);
    bodyFormData.append("source", source);
    bodyFormData.append("duration", this.state.videoduration);
    bodyFormData.append("files", file, file.name);

    const config = {
      headers: {
        "x-access-token": this.props.state.user.jwt,
        "content-type": "multipart/form-data"
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    };
    let res = await axios.post(url, bodyFormData, config);
    this.setState({
      loading: false
    });
    return res;
  };

  async componentDidMount() {
    this.windowGlobal = typeof window !== "undefined" ? window : false;
    let userdata = await this.props.fetchUserInfo(this.props.state.user.jwt);
    let activeOrg = this.props.activeMenu.activeOrg;

    if (activeOrg.id !== 0) {
      this.setState({
        loading: false
      });
      this.props.updateState({
        user: userdata
      });
    } else {
      navigate("/dashboard/hub/home");
    }
  }

  setCampaignType = event => {
    let value = _.get(event, "target.value", "28 Day");
    //console.log(value);
    this.setState({ campaignType: value });
  };
  getStartDate = () => {
    let activeOrg = this.props.activeMenu.activeOrg;
    let date = _.get(activeOrg, "fullresult.start_date", undefined);
    if (date && date !== "") {
      date = new Date(date).toISOString().split("T")[0];
    } else {
      date = null;
    }

    return moment(date).isValid() ? date : false;
  };
  getEndDate = () => {
    let activeOrg = this.props.activeMenu.activeOrg;
    let date = _.get(activeOrg, "fullresult.end_date", undefined);
    if (date && date !== "") {
      date = new Date(date).toISOString().split("T")[0];
    } else {
      date = undefined;
    }
    return moment(date).isValid() ? date : false;
  };
  FallBackComp = ({ display = false }) => {
    return (
      <input
        style={{
          display: display ? "block" : "none"
        }}
        onChange={e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState(
            {
              isShowVideoDialog: false,
              videofile: {
                ...this.state.videofile,
                videoBlob: e.target.files[0]
              }
            },
            () => {
              let file = this.state.videofile.videoBlob;
              let options = {
                source: "",
                ref: "donationpage",
                field:
                  this.state.videofile.videotype == 1
                    ? "User_slides"
                    : "user_final_slide"
              };
              this.fileUpload(file, options);
            }
          );
        }}
        ref={this.fallbackFile}
        type="file"
        accept="video/*,image/jpeg"
        capture="camera"
      />
    );
  };
  handleDateChange = async (e, force) => {
    let activeOrg = this.props.activeMenu.activeOrg;
    let { orgs } = this.props.state;
    let date;
    if (force) {
      date = new Date().toISOString();
    } else if (_.get(e, "target.value") && _.get(e, "target.value") !== "") {
      if (moment(_.get(e, "target.value")).isBefore(moment(), "day")) {
        date = undefined;
      } else {
        date = new Date(_.get(e, "target.value")).toISOString();
      }
    } else {
      date = undefined;
    }
    orgs = _.map(orgs, x => {
      if (x.isactive) {
        x.start_date = date;
      }
      return x;
    });
    this.props.updateState({
      orgs
    });
    OrgUpdate(this.apiHost, this.props.state.user.jwt, {
      start_date: date,
      donationpageid: activeOrg.donid
    });
  };

  render() {
    let activeOrg = this.props.activeMenu.activeOrg;
    //console.log(activeOrg);

    let user = this.props.state.user.user;
    // console.log('active', activeOrg);
    // console.log('user', this.props.state.user);
    if (_.get(this.state, "loading", true)) {
      return <LoadingCard />;
    }

    const { isShowVideoDialog } = this.state;

    const countdownTime = 2000;
    return (
      <>
        <this.FallBackComp />

        {isShowVideoDialog && (
          <VideoDialog
            onClose={() => {
              this.setState({
                isShowVideoDialog: false
              });
            }}
            open={isShowVideoDialog}
          >
            <div className="wrapper">
              <VisibilitySensor>
                <VideoRecorder
                  onStartRecording={() => {
                    const startTime = Date.now();
                    this.timer = setInterval(() => {
                      this.setState({ videoduration: Date.now() - startTime });
                    });
                  }}
                  onRecordingComplete={e => {
                    clearInterval(this.timer);
                    const duration =
                      this.state.videoduration - countdownTime - 1000;
                    console.log(duration);
                    ysFixWebmDuration(
                      e,
                      Number(duration - countdownTime - 1000),
                      fixedBlob => {
                        this.setState({
                          videofile: {
                            ...this.state.videofile,
                            videoBlob: blobToFile(fixedBlob, "video.webm")
                          }
                        });
                      }
                    );
                  }}
                  renderErrorView={e => {
                    return (
                      <>
                        <h3>
                          We had an error, Please choose a video file instead
                        </h3>
                        <this.FallBackComp display="true" />;
                      </>
                    );
                  }}
                  onError={e => {
                    //console.log(e.name);
                    //console.dir(e);
                    if (e.name !== "TypeError" && this.fallbackFile.current)
                      this.fallbackFile.current.click();
                  }}
                  // onStopRecording={console.log}q
                  isOnInitially={true}
                  timeLimit={1000 * 120}
                  countdownTime={countdownTime}
                  onStopReplaying={() => {
                    console.log(this.state.videoduration);
                    this.setState({
                      isShowVideoDialog: false
                    });
                    //uploadvideo now
                    let file = this.state.videofile.videoBlob;
                    let options = {
                      source: "",
                      ref: "donationpage",
                      field:
                        this.state.videofile.videotype == 1
                          ? "User_slides"
                          : "user_final_slide"
                    };
                    this.fileUpload(file, options);
                  }}
                  renderActions={customVideoAction}
                />
              </VisibilitySensor>
            </div>
          </VideoDialog>
        )}
        <Wrap>
          <ContentCardStyled
            onClick={e => {
              if (!!this.state.popperEl) {
                this.setState({
                  popperEl: undefined
                });
              }
            }}
            dimmed={!!this.state.popperEl}
          >
            <StyledModal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.videoPopup.isOpen}
              onClick={e => {
                e.preventDefault();
                this.setState({
                  videoPopup: {
                    ...this.state.videoPopup,
                    isPlaying: false,
                    isOpen: false
                  }
                });
              }}
              onClose={() => {
                this.setState({
                  videoPopup: {
                    ...this.state.videoPopup,
                    isOpen: false
                  }
                });
              }}
            >
              <div className="stage vertical">
                <StyledPlayer
                  autoPlay
                  onEnded={() => {
                    this.setState({
                      videoPopup: {
                        ...this.state.videoPopup,
                        isPlaying: false,
                        isOpen: false
                      }
                    });
                  }}
                  onPlay={e => {
                    this.setState({
                      videoPopup: {
                        ...this.state.videoPopup,
                        isPlaying: true
                      }
                    });
                  }}
                  preload="auto"
                  crossOrigin="anonymous"
                >
                  <BigPlayButton position="center" />
                  <source
                    style={{ outline: "0" }}
                    crossOrigin="anonymous"
                    src={this.state.videoPopup.video + "?no-cache=1"}
                  />
                </StyledPlayer>
              </div>
            </StyledModal>
            <DonationFormWrapper onSubmit={this.onFormSubmit}>
              <PageTitle>
                Edit/Publish My {_.get(activeOrg, "org.shortname", "")} Donation
                Page
              </PageTitle>
              <Column>
                <Section>
                  <SectionTitle>
                    Record your opening video
                    <InfoIcon
                      onClick={e => {
                        if (_.isUndefined(this.state.popperEl))
                          this.setState({
                            popperEl: e.target,
                            poppertext: `Your donation page will be presented in a social
                            media “stories” format. Taking the time to record an
                            enthusiastic yet thoughtful opening video is the
                            most important step you can take toward a successful
                            fundraiser. We suggest you watch the example video
                            before you begin and follow the outline provided on
                            the idea card in this section.`
                          });
                        else
                          this.setState({
                            popperEl: undefined
                          });
                      }}
                    />
                    <Popper
                      open={!!this.state.popperEl}
                      anchorEl={this.state.popperEl}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <div
                            style={{
                              padding: "2rem",
                              background: "white",
                              border: "1px solid #dedede",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "320px",
                              boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.98245)",
                              borderRadius: "6px"
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "HelveticaNeue-Medium",
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.2px",
                                color: "#062E60"
                              }}
                            >
                              {this.state.poppertext}
                            </p>
                          </div>
                        </Fade>
                      )}
                    </Popper>
                  </SectionTitle>
                  <SectionTextItalic marginBottom="1rem">
                    Click the RECORD button below and explain who, why, and what
                    you are raising money for.
                  </SectionTextItalic>
                  <TShapeDiv>
                    <div>
                      <BlueButton
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            videoPopup: {
                              ...this.state.videoPopup,
                              isOpen: true,
                              video: entryVideoExample
                            }
                          });
                        }}
                      >
                        CLICK HERE TO WATCH EXAMPLE
                      </BlueButton>
                    </div>
                  </TShapeDiv>
                </Section>
                <div
                  style={{
                    position: "relative",
                    display: "grid",
                    marginBottom: "2rem",
                    overflow: "hidden",
                    borderRadius: "5px"
                  }}
                >
                  {!_.isEmpty(
                    this.props.activeMenu?.activeOrg?.fullresult?.User_slides
                  ) ? (
                    <RecordFrame1>
                      <RecordingText>Recording:</RecordingText>
                      {console.log(this.state)}

                      <RecordingStatus>Successful</RecordingStatus>

                      <RedButton
                        onClick={e => {
                          e.preventDefault();
                          let isSafari = /^((?!chrome|android).)*safari/i.test(
                            this.windowGlobal.navigator.userAgent
                          );
                          if (!isSafari) {
                            this.setState({
                              isShowVideoDialog: true,
                              videofile: {
                                videotype: 1,
                                videoBlob: null
                              }
                            });
                          } else {
                            this.setState({
                              isShowVideoDialog: false,
                              videofile: {
                                videotype: 1,
                                videoBlob: null
                              }
                            });
                            this.fallbackFile.current.click();
                          }
                        }}
                      >
                        {" "}
                      </RedButton>
                    </RecordFrame1>
                  ) : (
                    <RecordFrame2>
                      <DateField>{curday("/")}</DateField>
                      <RecordingLength>Max Length: 2:00</RecordingLength>
                      <RecordingText>Recording:</RecordingText>
                      <RecordingStatus>Incomplete</RecordingStatus>

                      <RedButton
                        onClick={e => {
                          e.preventDefault();
                          let isSafari = /^((?!chrome|android).)*safari/i.test(
                            this.windowGlobal.navigator.userAgent
                          );
                          if (!isSafari) {
                            this.setState({
                              isShowVideoDialog: true,
                              videofile: {
                                videotype: 1,
                                videoBlob: null
                              }
                            });
                          } else {
                            this.setState({
                              isShowVideoDialog: false,
                              videofile: {
                                videotype: 1,
                                videoBlob: null
                              }
                            });
                            this.fallbackFile.current.click();
                          }
                        }}
                      >
                        {" "}
                      </RedButton>
                    </RecordFrame2>
                  )}
                </div>
              </Column>
              <Column>
                <Section>
                  <SectionTitle>Personal Profile Settings</SectionTitle>
                  <SectionTextItalic marginBottom="1rem">
                    Settings must be complete. Click{" "}
                    <Link to="/dashboard/hub/user/settings">HERE</Link> to
                    update.
                  </SectionTextItalic>
                  <SettingsWrapper>
                    <SettingsColumn>
                      <SettingsColumnImg
                        alt="plus icon"
                        src={user.profile_picture ? like : dislike}
                      />
                      <SettingsColumnTitle>Profile Picture</SettingsColumnTitle>
                    </SettingsColumn>
                    <SettingsColumn>
                      <SettingsColumnImg
                        alt="plus icon"
                        src={user.donation_page_username ? like : dislike}
                      />
                      <SettingsColumnTitle>Username</SettingsColumnTitle>
                    </SettingsColumn>
                  </SettingsWrapper>
                </Section>

                <Section
                  style={{
                    marginBottom: 0
                  }}
                >
                  <SectionTitle>Campaign Settings </SectionTitle>
                  <SectionTextItalic marginBottom="1rem">
                    Choose your fundraising goal and settings below.
                  </SectionTextItalic>
                  {/* <SliderWrapper>
                  <Slider
                    value={_.get(activeOrg, "goal")}
                    getAriaValueText={v => {
                      return `$ ${v}`;
                    }}
                    onChange={async (e, v) => {
                      let { orgs } = this.props.state;
                      orgs = orgs.map(x => {
                        if (x.isactive) {
                          x.goal = v;
                        }
                        return x;
                      });
                      this.props.updateState({
                        orgs
                      });

                      OrgUpdate(this.apiHost, this.props.state.user.jwt, {
                        goal: v,
                        donationpageid: activeOrg.donid
                      });
                    }}
                    aria-labelledby="discrete-slider-always"
                    step={500}
                    marks={[
                      {
                        value: 0,
                        label: "$0"
                      },
                      {
                        value: 500,
                        label: "$500"
                      },
                      {
                        value: 1000,
                        label: "$1,000"
                      },
                      {
                        value: 1500,
                        label: "$1,500"
                      },
                      {
                        value: 2000,
                        label: "$2,000"
                      },
                      {
                        value: 2500,
                        label: "Other"
                      }
                    ]}
                    min={0}
                    max={2500}
                    valueLabelDisplay="off"
                  />
                </SliderWrapper> */}
                  <FiedsetbottomHolder>
                    <SettingsColumnImg alt="plus icon" src={like} />
                    <p>My fundraising goal is:</p>
                    <AmountInputDate
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      inputProps={{
                        required: true,
                        min: 500,
                        step: 250
                      }}
                      fullWidth={false}
                      type="number"
                      value={_.get(activeOrg, "goal", 500)}
                      onChange={async e => {
                        let { orgs } = this.props.state;
                        orgs = orgs.map(x => {
                          if (x.isactive) {
                            x.goal = e.target.value;
                          }
                          return x;
                        });
                        this.props.updateState({
                          orgs
                        });

                        OrgUpdate(this.apiHost, this.props.state.user.jwt, {
                          goal: e.target.value,
                          donationpageid: activeOrg.donid
                        });
                      }}
                    />
                  </FiedsetbottomHolder>
                  <RowsDiv>
                    <div>
                      <SettingsColumnImg alt="plus icon" src={like} />
                      <p>Campaign Type:</p>
                      <p>
                        {(() => {
                          switch (
                            _.get(
                              activeOrg,
                              "fullresult.runtime_type",
                              undefined
                            )
                          ) {
                            case "event":
                              return "Event";
                            case "monthly":
                              return "Monthly";
                            case "days28":
                              return "28 Day";
                            default:
                              return "?";
                          }
                        })()}
                      </p>
                    </div>
                    <div>
                      <SettingsColumnImg
                        alt="plus icon"
                        src={this.getStartDate() ? like : dislike}
                      />
                      <p>
                        {(() => {
                          switch (
                            _.get(
                              activeOrg,
                              "fullresult.runtime_type",
                              undefined
                            )
                          ) {
                            case "event":
                              return "Campaign Start Date:";
                            case "monthly":
                              return "Campaign Start Date:";
                            case "days28":
                              return "Campaign Start Date:";
                            default:
                              return "Campaign Start Date:";
                          }
                        })()}
                      </p>
                      <p>
                        {_.get(
                          activeOrg,
                          "fullresult.runtime_type",
                          undefined
                        ) !== "monthly"
                          ? this.getStartDate()
                            ? moment(this.getStartDate()).format("MM/DD/Y")
                            : "--/--/---"
                          : "--/--/---"}
                      </p>
                    </div>
                    <div>
                      <SettingsColumnImg
                        alt="plus icon"
                        src={this.getEndDate() ? like : dislike}
                      />
                      <p>
                        {(() => {
                          switch (
                            _.get(
                              activeOrg,
                              "fullresult.runtime_type",
                              undefined
                            )
                          ) {
                            case "event":
                              return "Date of the event:";
                            case "monthly":
                              return "Campaign End Date:";
                            case "days28":
                              return "Campaign End Date:";
                            default:
                              return "Campaign End Date:";
                          }
                        })()}
                      </p>
                      <p>
                        {_.get(
                          activeOrg,
                          "fullresult.runtime_type",
                          undefined
                        ) !== "monthly"
                          ? this.getEndDate()
                            ? moment(this.getEndDate()).format("MM/DD/Y")
                            : "--/--/---"
                          : "--/--/---"}
                      </p>
                    </div>
                  </RowsDiv>
                  <SectionTextItalic
                    style={{
                      marginTop: ".25rem"
                    }}
                  >
                    Click{" "}
                    <Link to="/dashboard/hub/user/settings#manageaccount-cp">
                      HERE
                    </Link>{" "}
                    to update your campaign settings.
                  </SectionTextItalic>
                </Section>

                <BtnsWrapper>
                  <p
                    style={{
                      fontFamily: "HelveticaNeue-Medium",
                      gridColumn: "1 / span 2",
                      minWidth: "100%",
                      width: "100%"
                    }}
                  >
                    Once you have all{"   "}
                    <SettingsColumnImg alt="plus icon" src={like} />, then
                    you’re ready to publish your donation page
                  </p>
                  <BtnPreview
                    onClick={e => {
                      e.preventDefault();
                      let activeOrg = this.props.activeMenu.activeOrg;
                      this.windowGlobal.open(
                        this.windowGlobal.location.origin +
                          "/dashboard/donation/preview/" +
                          activeOrg.donid +
                          "#preview",
                        "_blank"
                      );
                    }}
                  >
                    PREVIEW
                  </BtnPreview>

                  <BtnPublish
                    style={{
                      minWidth: "100%",
                      width: "100%"
                    }}
                    disabled={
                      !this.getStartDate() ||
                      !user.profile_picture ||
                      !user.donation_page_username ||
                      !user.city ||
                      !user.state
                    }
                    type="submit"
                  >
                    {_.get(activeOrg, "fullresult.ispubed", false)
                      ? "unpublish"
                      : "publish"}
                  </BtnPublish>
                </BtnsWrapper>
              </Column>
              <Dialog
                onClose={() => {
                  this.setState({
                    dp: false
                  });
                }}
                aria-labelledby="simple-dialog-title"
                open={this.state.dp}
              >
                <PaddedDiv
                  style={{
                    width: "100%"
                  }}
                  className="dialog"
                >
                  <h4>
                    We have{" "}
                    {!_.get(activeOrg, "fullresult.ispubed", false)
                      ? "unpublished"
                      : "published"}{" "}
                    your page!
                  </h4>
                  {!_.get(activeOrg, "fullresult.ispubed", false) || (
                    <p>
                      This process takes a few seconds, your page will go live
                      starting{" "}
                      {moment(this.getStartDate()).isBefore(moment())
                        ? "today"
                        : moment(this.getStartDate()).fromNow()}
                      . Go{" "}
                      <Link to="/dashboard/active-org/add-donors/">here</Link>{" "}
                      to get your link to invite donors.
                    </p>
                  )}
                  {!_.get(activeOrg, "fullresult.ispubed", false) && (
                    <p>
                      We unpublished your page, this process will take a few
                      minutes.
                    </p>
                  )}
                  <DialogActions>
                    <BtnPreview
                      onClick={() => {
                        this.setState({
                          dp: false
                        });
                      }}
                    >
                      OK
                    </BtnPreview>
                  </DialogActions>
                </PaddedDiv>
              </Dialog>
            </DonationFormWrapper>
          </ContentCardStyled>
          <Masonry
            breakpointCols={{
              default: 4,
              1800: 3,
              1610: 1,
              1250: 2,
              850: 1
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <ContentVideoCard>
              <VideoCard
                title={`Creating a Donation Page`}
                text={`This video will show you how to create, customize and publish your own personal donation page in minutes. `}
                video={
                  "https://givhub-production.s3.us-east-2.amazonaws.com/ca17209f6ff14e27bacdef4757764d34.mp4"
                }
                videoposter={
                  "https://givhub-production.s3.us-east-2.amazonaws.com/82b9738ddd23407daf73908e31f2b266.png"
                }
              />
            </ContentVideoCard>
            <IdeaCard title={"Be Prepared… Be You!"}>
              <p>
                Before you record your “Welcome” or “Thank You” videos, write
                out what’s important. For your welcome video… <br />
                1) Introduce yourself and your cause, <br />
                2) Share why your cause is important to you, <br />
                3) Explain how support is used,
                <br />
                4) Tease the sweepstakes and prize, and
                <br />
                5) Ask for the donation
              </p>
            </IdeaCard>
          </Masonry>
        </Wrap>
      </>
    );
  }
}

const curday = sp => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //As January is 0.
  const yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return mm + sp + dd + sp + yyyy;
};
