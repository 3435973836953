import React, { Component } from "react";

import _ from "lodash";

import { getUser, fetchUserInfo, getUserOrgs } from "../../../services/auth";
import Bars from "../../../../static/img/assets/bars.svg";
import DonationPageTemplate from "../../../../src/templates/StoryDonation";
import LoadingCard from "../cards/LoadingCard";
export class DonationPreview extends Component {
  constructor(props) {
    super(props);

    this.donationpageid = props.id;
    this.state = {
      orgs: [],
      loading: true
    };
  }
  async componentDidMount() {
    let user = getUser();
    return await getUserOrgs(user.jwt)
      .then(d => {
        let result = d.filter(x => x.id == this.donationpageid);
        console.log(d, result);
        fetchUserInfo(user.jwt).then(userdata => {
          this.setState({
            orgs: [...result],
            user: userdata,
            loading: false
          });
        });
      })
      .catch(console.error);
  }
  render() {
    let _activeOrg = this.state.orgs[0];
    if (!this.state.loading && _activeOrg) {
      return (
        <>
          <DonationPageTemplate
            pageContext={{
              data: _activeOrg,
              url: "/" + "test",
              preview: true
            }}
          />
        </>
      );
    } else {
      return <LoadingCard />;
    }
  }
}
