import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import USOLogo from "../assets/usologo.png";
import lineImg from "../assets/line.png";
import longlineImg from "../assets/longline.png";
import ghlogoImg from "../assets/ghlogo.png";
import axios from "axios";
import whiteheart from "../assets/whiteheart.png";
import leftcaret from "../assets/leftcaret.png";
import tapJsonAnimation from "../assets/tap.json";
import kite from "../assets/kite.png";
import checkImg from "../assets/check.png";
import { Favorite as FavoriteIcon } from "@material-ui/icons";
//import Lottie from "react-lottie";
import _ from "lodash";
import moment from "moment";
import { DonateScreenPage } from "./DonateScreenPage";
import { GameScreenPage } from "./GameScreenPage";
import { sharefn } from "./utils";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";
const humanNumber = require("human-number");
import greyplaybutton from "../assets/greyplaybutton.png";
// import * as Stories from "react-insta-stories";
import { Grow } from "@material-ui/core";
import {
  StyledModal,
  StyledPlayer,
  BigPlayButton
} from "../../components/dashboard/styles/shell-styles";
import {
  DonateButton,
  DonorsBox,
  UserAvatar,
  LeftBar,
  RightBar,
  LogoStringHeader,
  SmallText,
  Subtext,
  Middle,
  ShareButton,
  SideFooter,
  Stage,
  BlueHeader,
  ArrowWrapper,
  WhiteHeader,
  CampLogo,
  SponsorsDiv,
  ScreenWrapper,
  STAGE_MAX_HEIGHT,
  Screen,
  FinalScreeen,
  StoryVideoWrapper
} from "./StoryStyles";

import { BottomSheet } from "./BottomSheet";

const getProgress = (apiHost, pageid) => {
  return async () => {
    return await axios
      .post(`${apiHost}/purchase/progress`, {
        donationpageid: pageid
      })
      .then(result => {
        if (!_.has(result, "data.progress") || !_.has(result, "data.goal"))
          return Promise.reject("no progress");
        result.data.goal = Math.max(
          Number(
            Number(
              result.data.progress + result.data.progress * 0.08
            ).toPrecision(2)
          ),
          result.data.goal * 100
        );
        return {
          progress: result.data.progress / 100,
          goal: result.data.goal / 100
        };
      })
      .catch(console.error);
  };
};
export const RightSide = ({
  userprofilepic,
  userlogopic,
  username,
  purchases,
  bgColorOverride
}) => {
  let [gWindow, setWindow] = useState(false);
  let donorBox = React.useRef();

  useEffect(() => {
    setWindow(typeof window !== "undefined" ? window : false);
  }, []);

  useEffect(() => {
    var elem = donorBox.current;
    var pos = 0;
    let con = 22;
    let stopThing = con * -5;
    let id;
    if (elem?.childNodes.length > 6) {
      id = setInterval(frame, 1000 * 3.5);
    }
    function frame() {
      if (pos < stopThing) {
        pos = pos + con + 4;
        stopThing = 0;
      } else {
        stopThing = con * -5;
        pos = pos - con - 4;
      }
      elem.style.transform = `translateY(${pos}px)`;
    }
    return () => {
      clearInterval(id);
    };
  }, []);

  // console.log("RS", { purchases });
  return (
    <RightBar>
      <UserAvatar alt="Daniel Nakhla" src={userprofilepic} />
      {userlogopic && <Img alt="Daniel Nakhla" src={userlogopic} />}
      {username && (
        <h2
          dangerouslySetInnerHTML={{
            __html: username
          }}
        />
      )}
      {_.size(purchases) && (
        <DonorsBox className="boxbox">
          <>
            <span>Thank you!</span>
            <div className="ex">
              <ul ref={donorBox}>
                {_.sortBy(purchases, "id")
                  .reverse()
                  .map(purchase => {
                    return (
                      <li key={purchase.id}>
                        <span
                          className="name"
                          dangerouslySetInnerHTML={{
                            __html: _.startCase(_.toLower(purchase.donor_name))
                          }}
                        />
                        <span
                          className="amount"
                          dangerouslySetInnerHTML={{
                            __html: "$" + humanNumber(purchase.amount / 100)
                          }}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </>
          <DonateButton
            backgroundColor={`#${bgColorOverride}`}
            onClick={e => {
              if (gWindow)
                window.location =
                  _.get(window, "location.pathname", "/") + "#donate";
            }}
          >
            <Img className="donateheart" src={whiteheart} alt="heart" />
            Donate Here
          </DonateButton>
          <ShareButton onClick={sharefn}>SHARE CAMPAIGN</ShareButton>
        </DonorsBox>
      )}

      <SideFooter>
        <Img src={ghlogoImg} alt="GH logo" />
        <a
          className="left"
          href="https://www.mygivhub.org/terms"
          target="_blank"
        >
          Terms of Use
        </a>
        <a
          className="right"
          href="https://www.mygivhub.org/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>
        <span>Copyright © 2018</span>
        <span>GivHub, All Rights Reserved</span>
      </SideFooter>
    </RightBar>
  );
};
const SponsorsImg = ({ sponsors }) => {
  return (
    <SponsorsDiv size={_.size(sponsors)}>
      {_.map(sponsors, (s, i) => {
        return (
          <VisibilitySensor key={i} delayedCall={true}>
            <Img src={_.get(s, "logo.url")} />
          </VisibilitySensor>
        );
      })}
    </SponsorsDiv>
  );
};

export const LeftSide = ({
  logo = USOLogo,
  header = "Fundraiser",
  sponsors = [],
  sponsor_text
}) => {
  sponsor_text = _.isNull(sponsor_text) ? "Partners:" : sponsor_text;
  return (
    <VisibilitySensor>
      <LeftBar>
        <Img src={logo} alt="logo" />
        <Img src={lineImg} alt="line" />
        <LogoStringHeader>{header}</LogoStringHeader>
        {/* <Img src={lineImg} alt="line" /> */}
        {/* <Subtext>NOV. 2019</Subtext> */}
        {/* <Img src={longlineImg} alt="line" /> */}
        <SmallText
          dangerouslySetInnerHTML={{
            __html: sponsor_text
          }}
        />
        <SponsorsImg sponsors={sponsors} />
        <Img src={longlineImg} alt="line" />
        <SmallText>Powered by GivHub</SmallText>
      </LeftBar>
    </VisibilitySensor>
  );
};

const GrowTrans = React.forwardRef((props, ref) => {
  return <Grow ref={ref} {...props} />;
});

export const MiddleSide = ({
  maxHeight,
  bgImage,
  fullCamp,
  end_date,
  isPreview,
  dbgoal = 1000,
  purchases,
  orglogo,
  orgName,
  orgShortname,
  orgMission,
  campMission,
  orgVideoPoster,
  isShowGoalBar,
  orgVideo,
  campVideoPoster,
  campVideo,
  userprofilepic,
  apiHost,
  pageid,
  updateFromDB,
  defaultfinalscreen,
  userslides,
  userfinalscreen
}) => {
  let [emailObject, setemailObject] = useState({
    orgname: orgName,
    didplay: false,
    didwin: false
  });
  let [gWindow, setWindow] = useState(false);
  let [Stories, SetStories] = useState({});
  let [isDonate, setDonate] = useState(false);
  let [isGame, setisGame] = useState(false);
  let [progress, setProgress] = useState(0);
  let [isFinalScreen, setisFinalScreen] = useState(false);
  let [goal, setGoal] = useState(dbgoal);
  let pget = getProgress(apiHost, pageid);
  let [isPaused, setisPaused] = useState(false);
  let [isLoading, setisLoading] = useState(true);
  let [isReady, setIsReady] = useState(true);
  let [isBottomOpen, setisBottomOpen] = useState(false);
  let [gameId, setGameId] = useState(false);
  //vid
  const [IsPlaying, setIsPlaying] = useState(false);
  //vid
  const setIsDonate = v => {
    setstoryCurrentIndex(v ? fullstories.length - 1 : 0);
  };
  const nextSlide = () => {
    setstoryCurrentIndex(storyCurrentIndex + 1);
  };

  const [pageData, setPageData] = useState({});
  const [storyCurrentIndex, setstoryCurrentIndex] = useState(0);
  const [heightOveride, setheightOveride] = useState(maxHeight);
  let [fullstories, setFullStories] = useState([]);
  let [isOpen, setOpen] = useState(false);
  const rootRef = React.useRef(null);
  // TODO: Josh
  //useEffect(() => {
  //  setTimeout(() => {
  //    pget().then(res => {
  //      setProgress(res.progress);
  //      setGoal(res.goal);
  //    });
  //  }, 1000);
  //}, [pageData]);

  useEffect(() => {
    setWindow(window);
    setIsReady(false);
    updateFromDB().then(result => {
      setPageData(result);
      let defaultThankyou = (isOpen, setOpen, setisPaused) => {
        console.log({ result });
        return {
          content: ({ action }) => {
            return (
              <Screen
                backgroundImage={_.get(
                  result,
                  "organization.campaign.defaultfirstslide.url"
                )}
                notAbsolute
              >
                <UserAvatar
                  style={{
                    top: "15rem",
                    left: "calc(50% - 55px)",
                    position: "absolute",
                    border: "1.5px solid #fff",
                    transform: "scale(1.40)"
                  }}
                  className="gradient-border"
                  alt="FundRaiser"
                  src={_.get(result, "user.profile_picture.url")}
                />
                <h3
                  style={{
                    top: "27rem",
                    position: "absolute"
                  }}
                >
                  Please join me in supporting <br />
                  {_.get(result, "organization.shortname")} An organization that
                  truly makes a difference!
                </h3>
                <span className="mess">
                  <br />
                  <br />
                </span>
              </Screen>
            );
          }
        };
      };
      let defaultFirst = (isOpen, setOpen, setisPaused) => {
        return {
          content: ({ action }) => {
            return (
              <Screen
                backgroundImage={_.get(
                  result,
                  "organization.campaign.defaultfirstslide.url"
                )}
                notAbsolute
              >
                <UserAvatar
                  style={{
                    top: "15rem",
                    left: "calc(50% - 55px)",
                    position: "absolute",
                    border: "1.5px solid #fff",
                    transform: "scale(1.45)"
                  }}
                  className="gradient-border"
                  alt="FundRaiser"
                  src={_.get(result, "user.profile_picture.url")}
                />
                <h3
                  style={{
                    top: "27rem",
                    position: "absolute"
                  }}
                >
                  Please join me in supporting <br />
                  {_.get(result, "organization.shortname")} An organization that
                  truly makes a difference!
                </h3>
                <span className="mess">
                  <br />
                  <br />
                </span>
              </Screen>
            );
          },
          header: {
            heading:
              _.get(result, "user.firstname") +
              " " +
              _.get(result, "user.lastname"),
            //  subheading: "Posted " + moment(x.updated_at).fromNow(),
            profileImage: _.get(result, "user.profile_picture.url")
          },
          duration: 1000 * 10
        };
      };
      let secondScreen = (isOpen, setOpen, setisPaused) => {
        return {
          content: ({ action }) => {
            return (
              <Screen notAbsolute>
                <div>
                  <h2>
                    Watch video
                    <br /> below to learn more
                    <br />
                    about {orgShortname}
                  </h2>
                  {/* <span>{isPaused ? "Paused" : "Playing"}</span> */}
                  <div
                    onClick={e => {
                      action("pause");
                      setOpen(true);
                      setisPaused(true);
                    }}
                    className="videowrapper"
                    style={{
                      zIndex: "1000"
                    }}
                  >
                    {isOpen || (
                      <>
                        <Img
                          style={{
                            width: "60px",
                            left: "calc(50% - 30px)"
                          }}
                          src={greyplaybutton}
                          alt="play"
                        />

                        <video disabled poster={campVideoPoster}>
                          {/* <source
                      crossOrigin="anonymous"
                      src={campVideo || orgVideo}
                      type="video/mp4"
                    /> */}
                        </video>
                      </>
                    )}
                  </div>
                </div>
              </Screen>
            );
          },
          header: {
            heading:
              _.get(result, "user.firstname") +
              " " +
              _.get(result, "user.lastname"),
            //  subheading: "Posted " + moment(x.updated_at).fromNow(),
            profileImage: _.get(result, "user.profile_picture.url")
          },
          duration: 1000 * 15
        };
      };

      let x = _.map(
        _.filter(
          [
            _.get(result, "User_slides", {}),
            ..._.get(result, `organization.campaign.default_screens`, [])
          ],
          x => _.has(x, "mime")
        ),
        x => ({
          url: x.url,
          type: x.mime.indexOf("image") === 0 ? "image" : "video",
          header: {
            heading:
              _.get(result, "user.firstname") +
              " " +
              _.get(result, "user.lastname"),
            //  subheading: "Posted " + moment(x.updated_at).fromNow(),
            profileImage: _.get(result, "user.profile_picture.url")
          }
        })
      );

      let donateScreen = {
        content: ({ action, isPaused }) => {
          return (
            <Screen>
              <DonateScreenPage
                closefn={e => {
                  if (e) e.preventDefault();
                  setDonate(false);
                }}
                setisGame={setisGame}
                sharefn={sharefn}
                purchases={purchases}
                dbgoal={goal}
                setIsDonate={setIsDonate}
                progressfn={pget}
                togglePause={() => {
                  action(isPaused ? "play" : "pause");
                }}
                shares={_.get(result, "shares", "0")}
                fullCamp={fullCamp}
                donationpageid={pageid}
                setGameId={setGameId}
                setisFinalScreen={setisFinalScreen}
                apiHost={apiHost}
                page_link={
                  "https://www.mygivhub.org" +
                  "/" +
                  _.get(result, "organization.campaign.url_slug", "") +
                  "/" +
                  _.get(result, "user.donation_page_username")
                }
                avatar={_.get(result, "user.profile_picture.url")}
                onSuccess={purchaseid => {
                  setemailObject({
                    ...emailObject,
                    purchaseid,
                    played_donation_page_link:
                      _.trim("https://www.mygivhub.org") +
                      "/" +
                      _.trim(
                        _.get(result, "organization.campaign.url_slug", "")
                      ) +
                      "/" +
                      _.trim(_.get(result, "user.donation_page_username"))
                  });
                }}
                updateFromDB={updateFromDB}
              ></DonateScreenPage>
            </Screen>
          );
        },
        duration: 60 * 1000,
        header: {
          heading:
            _.get(result, "user.firstname") +
            " " +
            _.get(result, "user.lastname"),
          //  subheading: "Posted " + moment(x.updated_at).fromNow(),
          profileImage: _.get(result, "user.profile_picture.url")
        }
      };

      if (_.size(_.get(result, "User_slides")) < 1) {
        x = [defaultFirst.call(this, isOpen, setOpen, setisPaused), ...x];
      }
      if (campVideo || orgVideo)
        x = [
          x[0],
          secondScreen.call(this, isOpen, setOpen, setisPaused),
          ...x.slice(1),
          donateScreen
        ];
      else x = [...x, donateScreen];

      setFullStories(x);
      let main = async () => {
        let result = await import("react-insta-stories");
        await SetStories(result);
        await setDonate(_.isEqual(window.location.hash, "#donate"));
        await setisLoading(false);
        setheightOveride(window.document.body.clientHeight - 30);
      };
      main();
    });
  }, []);

  let shouldShowPlayer =
    isReady && !isGame && !isFinalScreen && fullstories.length > 0;

  if (isLoading) {
    return <span />;
  }
  return (
    <Middle
      ref={rootRef}
      bgImg={bgImage}
      bgColorOverride={_.get(pageData, "organization.campaign.color_a", "")}
    >
      <>
        <StyledModal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          transition={GrowTrans}
          container={() => rootRef.current}
          onClose={e => {
            setOpen(false);
            nextSlide();
            setisPaused(false);
          }}
        >
          <div className="stage">
            <StyledPlayer
              autoPlay
              poster={campVideoPoster}
              onEnded={() => {
                setOpen(false);
                nextSlide();
                setisPaused(false);
              }}
            >
              <BigPlayButton position="center" />
              <source style={{ outline: "0" }} src={campVideo || orgVideo} />
            </StyledPlayer>
          </div>
        </StyledModal>
        {isReady && (
          <ArrowWrapper
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target.className == "disabled") {
                return;
              }
              if (!isDonate) {
                if (e.target.className == "goright") {
                  // player.next();
                  setstoryCurrentIndex(
                    Math.min(storyCurrentIndex + 1, _.size(fullstories))
                  );
                } else {
                  if (isDonate) {
                    setIsDonate(false);
                    return;
                  }
                  setstoryCurrentIndex(Math.max(storyCurrentIndex - 1, 0));
                }
              }
            }}
          >
            <Img
              src={leftcaret}
              className={storyCurrentIndex === 0 ? "disabled" : "goleft"}
              alt="left"
            />
            <Img
              src={leftcaret}
              className={
                storyCurrentIndex === _.size(fullstories) - 1
                  ? "disabled"
                  : "goright"
              }
              alt="right"
            />
          </ArrowWrapper>
        )}
        <Stage
          isReady={isReady}
          playing={isDonate}
          height={Math.max(900 || heightOveride)}
          bgColorOverride={_.get(pageData, "organization.campaign.color_a", "")}
        >
          {isReady && !isGame && !isFinalScreen && (
            <>
              <BlueHeader>
                {!isPreview && moment(end_date).isSameOrAfter() && (
                  <span>
                    Only <strong>{moment(end_date).fromNow(true)}</strong> left
                    to donate
                  </span>
                )}
                {isPreview && (
                  <span>
                    <strong>Donation Page Preview</strong>
                  </span>
                )}
                {moment(end_date).isBefore() && <span>Campaign Ended!</span>}
              </BlueHeader>
              <WhiteHeader></WhiteHeader>
            </>
          )}

          {shouldShowPlayer && (
            <Stories.default
              defaultInterval={1000 * 10}
              onAllStoriesEnd={e => {
                // console.log("all", e, ",", storyCurrentIndex);
              }}
              storyStyles={{
                objectFit: "cover",
                objectPosition: "top",
                top: "0px",
                height: "100%",
                minHeight: "-webkit-fill-available",
                minheight: STAGE_MAX_HEIGHT + "px",
                position: "absolute",
                width: "100%"
              }}
              // isPaused={isPaused || isOpen}
              currentIndex={storyCurrentIndex}
              stories={[...fullstories]}
              onStoryStart={e => {
                if (e !== storyCurrentIndex) {
                  setstoryCurrentIndex(e);
                }
              }}
              onStoryEnd={(e, rns_isPaused) => {
                console.log("x", e);
                if (
                  e + 1 !== storyCurrentIndex &&
                  e + 1 < _.size(fullstories)
                ) {
                  setstoryCurrentIndex(e + 1);
                }
              }}
              width={"100%"}
              height={
                gWindow.document.body.clientWidth < 500
                  ? "100vh"
                  : STAGE_MAX_HEIGHT
              }
            />
          )}

          {!isReady && !isDonate && !isGame && !isFinalScreen && (
            <div
              style={{
                display: "grid",
                placeContent: "center center"
              }}
              onClick={e => {
                e.stopPropagation();
                // setisPaused(false);
                // setIsDonate(false);
                setIsReady(true);
              }}
            >
              <UserAvatar
                className="gradient-border"
                alt="FundRaiser"
                src={userprofilepic}
              />
              {/*<Lottie*/}
              {/*  style={{*/}
              {/*    position: "absolute",*/}
              {/*    width: "100%",*/}
              {/*    height: "100%",*/}
              {/*    zIndex: " 10000",*/}
              {/*    display: "grid",*/}
              {/*    filter: "brightness(5)",*/}
              {/*    transform: "scale(.7)"*/}
              {/*  }}*/}
              {/*  options={{*/}
              {/*    loop: true,*/}
              {/*    autoplay: true,*/}
              {/*    animationData: tapJsonAnimation*/}
              {/*  }}*/}
              {/*  height={"300px"}*/}
              {/*  width={"300px"}*/}
              {/*/>*/}
            </div>
          )}

          {isGame && !isFinalScreen && (
            <>
              <GameScreenPage
                avatar={<UserAvatar alt="Daniel Nakhla" src={userprofilepic} />}
                link={emailObject.link}
                setisGame={setisGame}
                emailObject={emailObject}
                setemailObject={setemailObject}
                gameId={gameId}
                apiHost={apiHost}
                setisFinalScreen={setisFinalScreen}
              />
            </>
          )}

          {isFinalScreen && (
            <FinalScreeen
              backgroundImage={_.get(fullCamp, "defaultfirstslide.url")}
            >
              <CampLogo>
                <Img src={orglogo} alt="logo" />
              </CampLogo>
              <div>
                {pageData.organization?.campaign?.ThankYouVideo?.[0].url || (
                  <>
                    <UserAvatar
                      style={{
                        top: "12rem",
                        left: "calc(50% - 55px)",
                        position: "absolute",
                        border: "1.5px solid #fff",
                        transform: "scale(1.45)"
                      }}
                      className="gradient-border"
                      alt="FundRaiser"
                      src={_.get(pageData, "user.profile_picture.url")}
                    />
                    <h3
                      style={{
                        top: "24rem",
                        position: "absolute"
                      }}
                    >
                      Thank you for supporting <br />
                      {_.get(pageData, "organization.shortname")}. Giving is a
                      selfless act, and your gift means a lot!
                    </h3>
                    <span className="mess">
                      <br />
                      <br />
                    </span>
                  </>
                )}
                {pageData.organization?.campaign?.ThankYouVideo?.[0].url && (
                  <>
                    {pageData.organization.campaign.ThankYouVideo[0].url.match(
                      /\.(gif|jpe?g|tiff|png|webp|bmp)$/i
                    ) && (
                      <Img
                        src={
                          pageData.organization.campaign.ThankYouVideo[0].url
                        }
                      />
                    )}{" "}
                    {pageData.organization.campaign.ThankYouVideo[0].url.match(
                      /\.(mp4)$/i
                    ) && (
                      <video autoPlay={"autplay"}>
                        <source
                          preload="auto"
                          src={
                            pageData.organization.campaign.ThankYouVideo[0].url
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </>
                )}
                <span>
                  <button
                    onClick={() => {
                      setisFinalScreen(false);
                      setIsDonate(true);
                    }}
                  >
                    <Img src={whiteheart} />
                    {fullCamp.is_merch_checkout
                      ? "Buy More Merch!"
                      : fullCamp.is_show_game
                      ? " DONATE & PLAY AGAIN"
                      : "DONATE AGAIN"}
                  </button>
                  <button
                    onClick={() => {
                      sharefn();
                    }}
                  >
                    <Img src={kite} />

                    {fullCamp.is_merch_checkout
                      ? "SHARE THIS PAGE"
                      : "SHARE DONATION PAGE"}
                  </button>
                  <button
                    onClick={() => {
                      setIsDonate(false);
                      setisFinalScreen(false);
                    }}
                  >
                    <Img src={checkImg} />
                    I'M DONE
                  </button>
                </span>
              </div>
            </FinalScreeen>
          )}

          {isReady && !isGame && !isFinalScreen && (
            <>
              <CampLogo
                onClick={() => {
                  setIsDonate(!isDonate);
                }}
              >
                <Img src={orglogo} alt="logo" />
              </CampLogo>

              {storyCurrentIndex < 2 && !isDonate && (
                <>
                  {" "}
                  <DonateButton
                    style={{
                      backgroundColor: "#" + fullCamp.color_b
                    }}
                    backgroundColor={"#" + fullCamp.color_b}
                    onClick={() => {
                      setIsDonate(!isDonate);
                    }}
                  >
                    <FavoriteIcon /> Donate Here
                  </DonateButton>
                  <BottomSheet
                    isShowGoalBar={isShowGoalBar}
                    backgroundColor={`#${fullCamp.color_b}`}
                    isOpen={true}
                    isBottomOpen={isBottomOpen}
                    setisBottomOpen={setisBottomOpen}
                    isReady={isReady}
                    progress={progress}
                    goal={goal}
                    isDonate={isDonate}
                    setIsDonate={setIsDonate}
                    setIsPlaying={setIsPlaying}
                    orgVideoPoster={orgVideoPoster}
                    campVideoPoster={campVideoPoster}
                    orgMission={orgMission}
                    campMission={campMission}
                    campVideo={campVideoPoster}
                    orgShortname={orgShortname}
                  />
                </>
              )}
            </>
          )}
        </Stage>
      </>
    </Middle>
  );
};

export const MetaHeader = ({ page }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {_.get(page, "user.firstname", "") +
          " " +
          _.get(page, "user.lastname", "")}{" "}
        - {_.get(page, "organization.campaign.name", "")} Fundraiser | GivHub
      </title>
      <meta
        property="og:image"
        content={_.get(
          page,
          "user.profile_picture.url",
          "https://ui-avatars.com/api/?name=" +
            _.get(page, "user.firstname", "") +
            " " +
            _.get(page, "user.lastname", "")
        )}
      />
      <meta property="fb:app_id" content="317185642298062" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="mobile-web-app-capable" content="yes"></meta>
      <meta name="apple-mobile-web-app-capable" content="yes" />
    </Helmet>
  );
};
