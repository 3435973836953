const windowGlobal = typeof window !== "undefined" ? window : false;
import _ from "lodash";
export const sharefn = e => {
  if (e) e.preventDefault();
  if (windowGlobal && windowGlobal.navigator.share) {
    windowGlobal.navigator.share({
      title: document.title,
      url: _.get(window, "location.href")
    });
    return;
  }
  windowGlobal.FB.ui(
    {
      method: "share",
      href: _.get(window, "location.href")
    },
    response => {
      if (windowGlobal.ga && response && !response.error_message) {
        // alert('Posting completed.');
        windowGlobal.ga("send", "event", {
          eventCategory: "Social",
          eventAction: "Shared",
          eventLabel: "shared on facebook"
        });
      }
    }
  );
};
