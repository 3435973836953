import React, { useState } from "react";
import VideoIcon from "../../../../static/styles/icons/videoIcon.png";
import {
  StyledModal,
  StyledPlayer,
  PlayButton,
  PlayButtonWrapper,
  BigPlayButton
} from "../styles/shell-styles";
import PlayButtonPNG from "../../../../static/styles/images/playbutton.png";

export default ({ videoposter, video, text, title }) => {
  const [isOpen, setOpen] = useState(false);
  const [player, setPlayer] = useState(false);
  const [IsPlaying, setIsPlaying] = useState(false);
  return (
    <>
      <div
        style={{
          maxWidth: "320px"
        }}
      >
        <h4>
          <img
            src={VideoIcon}
            alt="video"
            style={{
              marginRight: "1rem"
            }}
          />
          {title}
        </h4>
        {text && <p>{text}</p>}
        <PlayButtonWrapper>
          <PlayButton
            onClick={e => {
              e.persist();
              if (player) {
                setTimeout(player.play.bind(this), 0);
                this.player.volume = 10;
              }
              setOpen(true);
            }}
            src={PlayButtonPNG}
            alt="play button"
          />

          <img
            onClick={() => {
              if (player) {
                setTimeout(player.play.bind(this), 0);
                this.player.volume = 10;
              }
              setOpen(true);
            }}
            style={{
              height: "190px"
            }}
            src={videoposter}
            alt="video thumb"
          />
        </PlayButtonWrapper>
        <StyledModal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={() => setOpen(false)}
        >
          <div className="stage">
            <StyledPlayer
              ref={s => setPlayer(s)}
              autoPlay
              onEnded={() => {
                setIsPlaying(false);
                setOpen(false);
              }}
              onPlay={e => {
                setIsPlaying(true);
              }}
              preload="auto"
              crossOrigin="anonymous"
            >
              <BigPlayButton position="center" />
              <source
                style={{ outline: "0" }}
                crossOrigin="anonymous"
                src={video + "?no-cache=1"}
              />
            </StyledPlayer>
          </div>
        </StyledModal>
      </div>
    </>
  );
};
