import React, { Component } from "react";
import Shell from "../Shell";
import _ from "lodash";
import { ContentCard } from "../styles/shell-styles";
import LoadingCard from "../cards/LoadingCard";

export const BlankCards = props => {
  return (
    <Shell {...props}>
      <BlankCardsScreen {...props} />
    </Shell>
  );
};

class BlankCardsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.setState({
      loading: false
    });
  }
  render() {
    if (this.state.loading) {
      return <LoadingCard />;
    }
    return (
      <>
        {_.map(this.props.cards, (c, i) => {
          return (
            <ContentCard key={i} className="loading">
              <h3>{c.title}</h3>
              <div>
                <p>Coming Soon!</p>
              </div>
            </ContentCard>
          );
        })}
      </>
    );
  }
}
