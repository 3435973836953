import React, { useState } from "react";
import whiteheart from "../assets/whiteheart.png";
import { ProgressBar, HeartImage } from "./StoryStyles";

export const BottomSheet = ({
  isBottomOpen,
  setisBottomOpen,
  isReady,
  progress,
  backgroundColor,
  goal,
  setIsDonate,
  isShowGoalBar,
  isDonate
}) => {
  return (
    <>
      {!isDonate && (
        <>
          <ProgressBar
            style={{
              position: "absolute",
              bottom: "3.5rem",
              top: "unset",
              zIndex: "900",
              visibility: isShowGoalBar ? "visible" : "hidden"
            }}
            show={!isBottomOpen}
            width={!isReady ? 0 : (progress / goal) * 100}
          >
            <span>
              <em>
                {progress.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}{" "}
              </em>
              raised of{" "}
              {goal.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}{" "}
              goal
            </span>
            <div>
              <div
                className="progress-child"
                style={{
                  minWidth: "15px",
                  backgroundColor: backgroundColor
                }}
              />
            </div>
          </ProgressBar>
          <HeartImage
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setIsDonate(!isDonate);
            }}
            backgroundColor={backgroundColor}
          >
            <img
              style={{
                zIndex: 1000
              }}
              className="donateheart"
              src={whiteheart}
              alt="heart"
            />
          </HeartImage>
        </>
      )}
      <span
        onClick={e => {
          setisBottomOpen(!isBottomOpen);
        }}
      ></span>
    </>
  );
};
