import React, { useState } from "react";
import { navigate } from "gatsby";
import ContentModal from "../../common/ContentModal";
import { PaddedDiv } from "../styles/shell-styles";

export default ({ titleImage, CTAButton }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      style={{
        padding: 0
      }}
    >
      <img
        style={{
          minWidth: "100%",
          minHeight: "20%"
        }}
        src={titleImage}
        alt="become a hubraiser thumb"
      />
      <PaddedDiv>
        {/* <h3>Task</h3> */}
        <h4>Should you become a Verified Hubraiser?</h4>
        <p>
          Are you an Advisor, Coach, Teacher, Agent, Director, Manager or
          Non-profit Executive? Become a Verified Hubraiser so that you can
          manage menu functions, choose campaign dates, add merchandise, publish
          your GivMore Store, connect accounts, and access special reports by
          clicking the button below!
        </p>
        <CTAButton
          onClick={() => {
            navigate("/dashboard/hub/user/settings#becomeaverifiedhubraiser");
          }}
        >
          Complete Form
        </CTAButton>
      </PaddedDiv>
    </div>
  );
};
