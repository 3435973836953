import React from "react";
export default () => {
  return (
    <>
      <div style={{ padding: "2rem 2rem 0" }}>
        {/* <h3>NEWS</h3> */}
        <div>
          <h4>New Feature Release Dates…</h4>
          <p>
            It’s an exciting time at GivHub as we improve existing functionality
            and launch new features.
          </p>
        </div>
      </div>
      <table border="0">
        <thead>
          <tr>
            <th>New Features</th>
            <th>Release Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Hubraiser Rewards</td>
            <td>Feburary 1</td>
          </tr>
          <tr>
            <td>Create a Personal Campaign</td>
            <td>June 1</td>
          </tr>
          <tr>
            <td>GivMore Store Opens</td>
            <td>June 1</td>
          </tr>
          <tr>
            <td>Create Events & Forms</td>
            <td>June 1</td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </>
  );
};
